import "./landing.scss";
import { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { IoLogOutOutline } from "react-icons/io5";
import { GoTasklist } from "react-icons/go";
import { CiClock1, CiViewList } from "react-icons/ci";
import { GrUserWorker } from "react-icons/gr";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineCorporateFare, MdEventNote } from "react-icons/md";
import { AiFillDislike, AiFillLike, AiFillProject } from "react-icons/ai";
import { like, dislike } from "../../apis/user"

// components
import Mainheader from "../../components/mainheader";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Licenses from "../../components/licenses";

const mastheadMessage = "and MANAGE THEM without a single headache or frustration"
const message = "We enable you to move away (detach) from your work and relax whenever you want and your life on DETACH will stay organized"

export default function Landing() {
  const [dislikeBox, setDislikebox] = useState({ show: false, input: '', sent: false })
  const [likeBox, setLikebox] = useState({ show: false, input: '', sent: false })
  const [showLicense, setShowlicense] = useState(false)

  const theLike = () => {
    if (!likeBox.show) {
      const data = { type: 'num' }

      like(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const info = document.getElementById("landing-page")

            info.scrollTop = 0
            info.style.overflow = "hidden"

            setLikebox({ ...likeBox, show: true, input: '' })
          }
        })
    } else {
      const data = { type: 'input', input: likeBox.input }

      like(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const info = document.getElementById("landing-page")

            info.scrollTop = 0
            info.style.overflow = "auto"

            setLikebox({ ...likeBox, sent: true })

            setTimeout(function () {
              setLikebox({ ...likeBox, show: false, sent: false })
              window.scrollTo(0, 0)
            }, 2000)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const theDislike = () => {
    if (!dislikeBox.show) {
      const info = document.getElementById("landing-page")

      info.scrollTop = 0
      info.style.overflow = "hidden"

      setDislikebox({ ...dislikeBox, show: true, input: '' })
    } else {
      const { input } = dislikeBox

      if (input) {
        const data = { input }

        dislike(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              const info = document.getElementById("landing-page")

              info.scrollTop = 0
              info.style.overflow = "auto"

              setDislikebox({ ...dislikeBox, input: '', errorMsg: '', sent: true })

              setTimeout(function () {
                setDislikebox({ ...dislikeBox, show: false, sent: false })
                window.scrollTo(0, 0)
              }, 2000)
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      } else {
        setDislikebox({ ...dislikeBox, errorMsg: "Please enter your opinion" })
      }
    }
  }
  
  return (
    <div id="landing-page">
      <BrowserView>
        <div id="landing-page-web">
          <Header/>

          <div id="first-masthead">
            <div id="first-masthead-headers">
              <div id="first-masthead-miniheaders">
                <h1 className="masthead-miniheader">Create your</h1>
                <div id="list-items">
                  <div className="list-item">
                    <div className="list-item-icon">
                      <MdEventNote style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">Notes</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <CiClock1 style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">Schedules</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">Todolists</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">Projects</div></div>
                  </div>

                  <div id="extra">
                    <div id="extra-header">AND ALSO</div>

                    <div className="list-item">
                      <div className="list-item-icon">
                        <GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Members</div></div>
                    </div>
                    <div className="list-item">
                      <div className="list-item-icon">
                        <IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Teams</div></div>
                    </div>
                    <div className="list-item">
                      <div className="list-item-icon">
                        <MdOutlineCorporateFare style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Companies</div></div>
                    </div>
                  </div>
                </div>
                <h1 className="masthead-miniheader">{mastheadMessage}</h1>
              </div>
            </div>
            <div id="first-masthead-image" className="column">
              <div><img src="/masthead.jpg"/></div>
            </div>
          </div>
          <div id="second-masthead">
            <div id="second-masthead-headers">
              <h2 id="second-masthead-header">{message}</h2>
            </div>
            <div id="second-masthead-image" className="column">
              <div>
                <img src="/beach.jpg"/>
              </div>
            </div>
          </div>
          <div id="desktop-presenting">
            <div className="column">
              <div id="presenting-headers">
                <div id="desktop-header">
                  Get the<br/>
                  Windows or macOS<br/>
                  application<br/>
                  to have quick access
                </div>

                <div id="desktop-downloads">
                  <div className="desktop-download" onClick={() => document.getElementById("download-mac").click()}>Download for macOS</div>
                  <div className="desktop-download" onClick={() => document.getElementById("download-win").click()}>Download for Windows</div>
                </div>

                <a id="download-mac" style={{ display: 'none' }}/>
                <a id="download-win" style={{ display: 'none' }}/>
              </div>
            </div>
            <div id="masthead-image" className="column">
              <div>
                <img src="/desktop.jpeg"/>
              </div>
            </div>
          </div>
          <div id="demo">
            <h2 id="demo-video-header">
              A 24 seconds video demonstration of creating a to-do list
            </h2>
            <div id="demo-video-holder">
              <video src="/short_tutorials/web/create-todolist-with-tasks.mp4" controls/>
            </div>
            <div className="row">
              <div id="demo-video-actions">
                <div className="demo-video-action" onClick={() => theDislike()}><AiFillDislike style={{ color: "black", height: '100%', width: '100%' }}/></div>
                <div className="demo-video-action" onClick={() => theLike()}><AiFillLike style={{ color: "black", height: '100%', width: '100%' }}/></div>
              </div>
            </div>
            <div id="getin-header">It's very easy!</div>
          </div>
          <div id="short-tuts">
            <h2 id="short-tuts-header">
              Watch Videos (in-seconds) Tutorial(s)
              <div style={{ fontSize: 20, fontWeight: 100 }}>(for proof of EASINESS and HEADACHE-PROOF)</div>
            </h2>

            <div id="tut-videos">
              <div className="tut-video">
                <div className="tut-video-header">Create a to-do list</div>
                <video src="/short_tutorials/web/create-todolist-with-tasks.mp4" controls/>
              </div>
              <div className="tut-video">
                <div className="tut-video-header">Add a new member</div>
                <video src="/short_tutorials/web/landing/addnewuser.mp4" controls/>
              </div>
              <div className="tut-video">
                <div className="tut-video-header">Add a worker to a to-do list</div>
                <video src="/short_tutorials/web/landing/add-user-to-todolist.mp4" controls/>
              </div>
              <div className="tut-video">
                <div className="tut-video-header">Add a new task to to-do list</div>
                <video src="/short_tutorials/web/landing/add-task-to-todolist.mp4" controls/>
              </div>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {(dislikeBox.show || likeBox.show || showLicense) && (
            <div id="hidden" onClick={() => {
              setDislikebox({ ...dislikeBox, show: false, input: '', sent: false })
              setLikebox({ ...likeBox, show: false, input: '', sent: false })
              setShowlicense(false)
            }}>
              {dislikeBox.show && (
                <div id="dislike-box" onClick={(e) => e.stopPropagation()}>
                  <div id="dislike-header">Please tell us your opinion (Anonymously)</div>

                  <div id="dislike-input" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <textarea 
                      placeholder="Any words will be very appreciated" 
                      value={dislikeBox.input}
                      onChange={e => {
                        if (!dislikeBox.sent) {
                          setDislikebox({ ...dislikeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="dislike-actions" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setDislikebox({ ...dislikeBox, show: false, input: '', errorMsg: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        theDislike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {dislikeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {likeBox.show && (
                <div id="like-box" onClick={(e) => e.stopPropagation()}>
                  <div id="like-header">Any suggestion you want to add (Anonymously)</div>

                  <div id="like-input" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <textarea
                      placeholder="Any words will be very appreciated (Optional)"
                      value={likeBox.input}
                      onChange={e => {
                        if (!likeBox.sent) {
                          setLikebox({ ...likeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="like-actions" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setLikebox({ ...likeBox, show: false, input: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        theLike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {likeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {showLicense && <Licenses />}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="landing-page-mobile">
          <Header/>

          <div id="first-masthead">
            <div id="first-masthead-headers">
              <div id="first-masthead-miniheader">
                <h1 className="first-masthead-miniheader">Create your</h1>

                <div id="list-items">
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <MdEventNote style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Notes</div></div>
                    </div>
                    <div className="list-item">
                      <div className="list-item-icon">
                        <CiClock1 style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Schedules</div></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Todolists</div></div>
                    </div>
                    <div className="list-item">
                      <div className="list-item-icon">
                        <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">Projects</div></div>
                    </div>
                  </div>

                  <div id="extra">
                    <div id="extra-header">AND ALSO</div>

                    <div className="row">
                      <div className="list-item">
                        <div className="list-item-icon">
                          <GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                        <div className="column"><div className="list-item-header">Employees</div></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="list-item">
                        <div className="list-item-icon">
                          <MdOutlineCorporateFare style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                        <div className="column"><div className="list-item-header">Companies</div></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="list-item">
                        <div className="list-item-icon">
                          <IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                        <div className="column"><div className="list-item-header">Teams</div></div>
                      </div>
                    </div>
                  </div>
                  
                </div>

                <h1 className="first-masthead-miniheader">{mastheadMessage}</h1>
              </div>
            </div>
            <div id="first-masthead-image" className="column">
              <div>
                <img src="/masthead.jpg"/>
              </div>
            </div>
          </div>
          <div id="second-masthead">
            <div id="second-masthead-headers">
              <div id="second-masthead-header">{message}</div>
            </div>
            <div id="second-masthead-image" className="column">
              <img src="/beach.jpg"/>
            </div>
          </div>
          <div id="demo">
            <h2 id="demo-video-header">
              Get the app for<br/>Windows or macOS<br/>application<br/>to have quick access
            </h2>
            <div id="demo-screenshot-holder">
              <img src="/desktop.jpeg"/>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {(dislikeBox.show || likeBox.show || showLicense) && (
            <div id="hidden" onClick={() => {
              setDislikebox({ ...dislikeBox, show: false, input: '', sent: false })
              setLikebox({ ...likeBox, show: false, input: '', sent: false })
              setShowlicense(false)
            }}>
              {dislikeBox.show && (
                <div id="dislike-box" onClick={(e) => e.stopPropagation()}>
                  <div id="dislike-header">Please tell us your opinion (Anonymously)</div>

                  <div id="dislike-input" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <textarea 
                      placeholder="Any words will be very appreciated" 
                      value={dislikeBox.input}
                      onChange={e => {
                        if (!dislikeBox.sent) {
                          setDislikebox({ ...dislikeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="dislike-actions" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setDislikebox({ ...dislikeBox, show: false, input: '', errorMsg: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        theDislike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {dislikeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {likeBox.show && (
                <div id="like-box" onClick={(e) => e.stopPropagation()}>
                  <div id="like-header">Any suggestion you want to add (Anonymously)</div>

                  <div id="like-input" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <textarea
                      placeholder="Any words will be very appreciated (Optional)"
                      value={likeBox.input}
                      onChange={e => {
                        if (!likeBox.sent) {
                          setLikebox({ ...likeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="like-actions" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setLikebox({ ...likeBox, show: false, input: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        theLike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {likeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {showLicense && <Licenses />}
            </div>
          )}
        </div>
      </MobileView>
    </div>
  )
}
