import './dashboard.scss';
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect"
import { IoIosNotificationsOutline, IoIosAdd, IoMdClose, IoIosMove, IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosPeople } from "react-icons/io";
import { AiOutlineUserAdd, AiOutlineLoading3Quarters, AiFillProject } from "react-icons/ai";
import { FaArrowLeft, FaChevronDown, FaChevronUp, FaArrowRight, FaUser, FaClock, FaCheck } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { FaRegFolderClosed, FaCircleCheck, FaRegClock, FaArrowDownLong } from "react-icons/fa6";
import { IoLogOutOutline, IoAddCircleOutline, IoCalendarNumberOutline, IoCalendarOutline } from "react-icons/io5";
import { MdFormatListBulleted, MdOutlineTextFields, MdEventNote, MdOutlineClose, MdEdit, MdWorkOutline, MdOutlineCorporateFare } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { FiZoomIn, FiZoomOut, FiEdit2 } from "react-icons/fi";
import { GoTasklist } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { GrNotes, GrFormPrevious, GrFormNext, GrUserWorker } from "react-icons/gr";
import { LuPencil } from "react-icons/lu";
import { CgNotes } from "react-icons/cg";
import { getNotifications, getUserInfo, getUsage, readedIntro, addNewMember, searchWorkers, selectWorker, getProgress, isExceedUsage, getUsageRecords, requestFeature } from "../../apis/user"
import { getChoices, addChoice, moveInBetween, moveChoice, checkValidBetween, removeParent, deleteChoice, postponeTask, completedTask, viewTaskInfo } from "../../apis/choice"
import { addNotes, seeNotes } from "../../apis/note"
import { postComment, deleteComment, saveComment } from "../../apis/comment"

// components
import Mainheader from "../../components/mainheader"
import Usagerecords from "../../components/usagerecords";
import Report from "../../components/report";
import Notifications from "../../components/notifications";
import Footer from "../../components/footer";
import Licenses from "../../components/licenses";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const levelsDict = {
  "Folder": {
    index: 0,
    allowed: [0, 1, 2, 3, 4, 5, 6]
  },
  "Company": {
    index: 1,
    allowed: [0, 1, 2, 3, 4, 5]
  },
  "Team": {
    index: 2,
    allowed: [3, 4, 5, 6]
  },
  "Project": {
    index: 3,
    allowed: [2, 3, 4, 5]
  },
  "Member": {
    index: 4,
    allowed: [4, 5]
  },
  "Todolist": {
    index: 5,
    allowed: [6]
  },
  "Task": {
    index: 6,
    allowed: []
  },
  
  // only
  "CompanyOnly": {
    allowed: [0]
  },
  "TeamOnly": {
    allowed: [1, 2, 3, 4]
  },
  "ProjectOnly": {
    allowed: [1, 2]
  },
  "MemberOnly": {
    allowed: [3, 4]
  }
}

const TimeFrameIcon = ({ size }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: size, justifyContent: 'space-around', width: size }}>
      <div style={{ display: 'flex', flexDirection: 'row', height: '50%', justifyContent: 'space-between', width: '100%' }}>
        <IoCalendarNumberOutline style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
        <IoCalendarNumberOutline style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
      </div>
      <FaClock style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
    </div>
  )
}

export default function Dashboard() {
  const [token, setToken] = useState('')
  const [addedBy, setAddedby] = useState(null)
  const [name, setName] = useState('')
  const [numNotifications, setNumnotifications] = useState(0)
  const [numUsages, setNumusages] = useState(0.0)
  const [notification, setNotification] = useState({ show: false, list: [], loading: false })
  const [scale, setScale] = useState(1)
  const [choiceEdited, setChoiceedited] = useState(false)
  const [updateNumusages, setUpdatenumusages] = useState(false)
  const [showInactiveplan, setShowinactiveplan] = useState(false)

  // layouts
  const [progress, setProgress] = useState([])
  const [progressLoading, setProgressloading] = useState(true)
  const [showProgress, setShowprogress] = useState(false) // mobile only
  const [datas, setDatas] = useState([])
  const [dataLoading, setDataloading] = useState(true)
  const [movingChoice, setMovingchoice] = useState({ active: false })

  // popup boxes
  const [introBox, setIntrobox] = useState({ show: false, step: 0 })
  const [addChoicebox, setAddchoicebox] = useState({ show: false, parentId: 0, data: null, dir: "", between: "" })
  const [addCompany, setAddcompany] = useState({ 
    show: false, name: '', 
    setHours: false,
    hours: {
      "Sun": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Mon": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Tue": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Wed": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Thu": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Fri": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Sat": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } }
    },
    loading: false
  })
  const [addTeam, setAddteam] = useState({ show: false, name: '', errorMsg: "", loading: false })
  const [addProject, setAddproject] = useState({ 
    show: false, name: '', 
    scheduled: null,
    timeType: '',
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    errorMsg: "",
    loading: false
  })
  const [addWorker, setAddworker] = useState({ 
    show: false, 
    info: null,
    name: '', // to be selected from searched list
    list: [],
    loading: false,
    showList: false,
    noMembers: false // display this if no members are found
  })
  const [addTask, setAddtask] = useState({
    show: false, 
    name: "",
    workerId: 0,
    todolistId: 0,
    timeType: '',
    otherData: {},
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    }
  })
  const [addNote, setAddnote] = useState({ 
    show: false, type: '', 
    parentId: 0,
    parentName: '', 
    bullets: [{
      editing: true,
      value: ''
    }], 
    paragraphs: ''
  })
  const [addTodolist, setAddtodolist] = useState({
    show: false,
    name: "",
    list: [],
    workerId: 0,
    errorMsg: "",
    loading: false
  })
  const [addNewmember, setAddnewmember] = useState({
    show: false, 
    firstName: process.env.REACT_APP_PROD === "prod" ? "" : "Sarah", 
    lastName: process.env.REACT_APP_PROD === "prod" ? "" : "Li", 
    email: process.env.REACT_APP_PROD === "prod" ? "" : "usertester60000@outlook.com",
    setHours: false,
    workingHours: {
      "Sun": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Mon": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Tue": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Wed": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Thu": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Fri": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
      "Sat": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } }
    },
    errorMsg: "",
    loading: false
  })

  // mobile only
  const [timeInfo, setTimeinfo] = useState({ show: false })
  // end mobile only

  const [deleteChoicebox, setDeletechoicebox] = useState({ show: false, id: null, type: '', name: '', loading: false })
  const [postponeTaskbox, setPostponetaskbox] = useState({ 
    show: false, 
    id: 0, 
    name: '', 
    timeType: '',
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-5",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-6",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-5",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-6",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    errorMsg: "",
    loading: false
  })
  const [seeNotesbox, setSeenotesbox] = useState({ show: false, header: '', type: '', content: null })
  const [seeTaskinfobox, setSeetaskinfobox] = useState({ 
    show: false, info: null, id: -1,
    comments: [], parents: [],
    comment: '', errorMsg: "", loading: false
  })
  const [showExceedusagebox, setShowexceedusagebox] = useState({ show: false, nextPlan: "" })
  const [usageRecords, setUsagerecords] = useState({
    show: false,
    showUsage: false,
    plan: "",
    company: { use: 0, limit: 0 },
    project: { use: 0, limit: 0 },
    team: { use: 0, limit: 0 },
    member: { use: 0, limit: 0 },
    todolist: { use: 0, limit: 0 },
    task: { use: 0, limit: 0 }
  })
  const [moveLoading, setMoveloading] = useState(false)
  const [report, setReport] = useState(false)
  const [requestFeaturebox, setRequestfeaturebox] = useState({ show: false, input: '', isPrivate: false, loading: false })
  const [showLicenses, setShowlicenses] = useState(false)

  const [errorMsg, setErrormsg] = useState('')

  const getScheduledStamp = info => {
    let scheduled = null
    let { selectedDate, monthNum, yearNum } = info.calendar
    let { hour, minute, period } = info.time
    let parseStr = months[monthNum] + " " + selectedDate + ", " + yearNum + " " + 
      (
        period === "am" ? 
          hour === 12 ? 0 : hour
          : 
          hour < 12 ? parseInt(hour) + 12 : hour
      )
      + ":" + minute

    if (selectedDate > 0) {
      const duestamp = selectedDate > 0 ? Date.parse(parseStr) : 0

      scheduled = duestamp / 1000
    }

    return scheduled
  }
  const getTimeStr = info => { // mobile only
    let { selectedDate, monthNum, yearNum } = info.calendar
    let { hour, minute, period } = info.time
    let parseStr = months[monthNum] + " " + selectedDate + ", " + yearNum + " " + 
      (
        period === "am" ? 
          hour === 12 ? 0 : hour
          : 
          hour < 12 ? parseInt(hour) + 12 : hour
      )
      + ":" + minute

    return parseStr
  }
  const getTheUserInfo = () => {
    const data = { token }

    getUserInfo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.readedIntro) {
            getTheProgress()
          } else {
            setIntrobox({ ...introBox, show: true, step: 0 })
          }

          const status = res.planPaused.split(":")

          setName(res.name)

          if (status[0] == "true") {
            setShowinactiveplan(true)
          }

          getTheNotifications('num')
          getTheUsage()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getTheUsage = () => {
    const data = { token, dataType: "" }

    getUsage(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res.msg === "stopUsage") {
          setNumusages(100)
        } else if (res.msg === "unlimited") {
          setNumusages(null)
        } else {
          setNumusages(res.msg)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getTheNotifications = type => {
    const data = { token: localStorage.getItem("token") }

    if (type) {
      data['getnum'] = true
    }

    getNotifications(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (type) {
            setNumnotifications(res.num)
          } else {
            setNotification({ ...notification, show: true, list: res.notifications, loading: false })
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const readedTheIntro = () => {
    const data = { token }

    readedIntro(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setIntrobox({ ...introBox, show: false, step: 0 })
          getTheProgress()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const isTheExceedUsage = type => {
    const data = { token, dataType: type }

    isExceedUsage(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { msg } = res
          const msgSpt = msg.split(":")

          if (msgSpt[1] == "false") {
            if (msgSpt[0] == "false") {
              if (msg.split(":")[1] === "false") {
                if (type === "Company") {
                  setAddchoicebox({ ...addChoicebox, show: false })
                  setAddcompany({ 
                    ...addCompany, 
                    show: true, name: '', 
                    setHours: false,
                    hours: {
                      "Sun": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Mon": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Tue": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Wed": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Thu": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Fri": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                      "Sat": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } }
                    }
                  })
                } else if (type === "Team") {
                  setAddchoicebox({ ...addChoicebox, show: false })
                  setAddteam({ ...addTeam, show: true, errorMsg: "", loading: false })
                } else if (type === "Project") {
                  setAddchoicebox({ ...addChoicebox, show: false })
                  setAddproject({ 
                    ...addProject, show: true, name: "", 
                    startEnabled: false,
                    endEnabled: false
                  })
                } else if (type === "Member") {
                  setAddchoicebox({ ...addChoicebox, show: false })
                  setAddworker({ 
                    ...addWorker, 
                    show: true, info: null,
                    name: '', // to be selected from searched list
                    list: [],
                    showList: false,
                    noMembers: false // display this if no members are found
                  })
                } else if (type === "Todolist") {
                  setAddchoicebox({ ...addChoicebox, show: false })
                  setAddtodolist({ 
                    ...addTodolist, 
                    show: true, 
                    name: "",
                    list: [],
                    workerId: 0
                  })
                } else if (type === "Task") {
                  return "valid"
                }
              } else {
                setAddchoicebox({ ...addChoicebox, show: false })
                setShowexceedusagebox({ ...showExceedusagebox, show: true })
              }
            } else {
              setAddchoicebox({ ...addChoicebox, show: false })
              setShowexceedusagebox({ ...showExceedusagebox, show: true })
            }
          } else {
            setShowinactiveplan(true)
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "noMembers":
                setAddchoicebox({ ...addChoicebox, show: false })
                setAddworker({ ...addWorker, show: true, noMembers: true })

                break;
            }
          })
        }
      })
  }
  const getTheUsageRecords = () => {
    const data = { token }

    getUsageRecords(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {          
          if (res.usages) {
            setUsagerecords({
              ...usageRecords,
              show: true,
              showUsage: true,
              plan: res.plan,
              ...res.usages
            })
          } else {
            setUsagerecords({
              ...usageRecords,
              show: true,
              plan: res.plan
            })
          }
        }
      })
  }
  const getTheProgress = () => {
    setProgressloading(true)

    const data = { token }

    getProgress(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {          
          const progress = [...res.progress]
          const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

          progress.forEach(function (info) {
            // get end date
            let endDateInfo = new Date(info.end * 1000)

            let day = days[endDateInfo.getDay()]
            let month = months[endDateInfo.getMonth()]
            let date = endDateInfo.getDate()
            let year = endDateInfo.getFullYear()

            let hour = endDateInfo.getHours()
            let minute = endDateInfo.getMinutes()

            info.end = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")

            // get created date
            let createdDateInfo = new Date(info.created * 1000)

            day = days[createdDateInfo.getDay()]
            month = months[createdDateInfo.getMonth()]
            date = createdDateInfo.getDate()
            year = createdDateInfo.getFullYear()

            hour = createdDateInfo.getHours()
            minute = createdDateInfo.getMinutes()

            info.created = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")

            let startDateInfo = new Date(info.start * 1000)

            day = days[startDateInfo.getDay()]
            month = months[startDateInfo.getMonth()]
            date = startDateInfo.getDate()
            year = startDateInfo.getFullYear()

            hour = startDateInfo.getHours()
            minute = startDateInfo.getMinutes()

            info.start = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")
          })

          setProgress(progress)
          setProgressloading(false)
          getTheChoices()
        }
      })
      .catch((err) => {

      })
  }
  const getTheChoices = () => {
    setDataloading(true)
    setErrormsg("")

    const data = { token }

    getChoices(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setDatas(res.datas)
          setDataloading(false)
          setUpdatenumusages(false)
          setMoveloading(false)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const addTheCompany = () => {
    const { name, hours, setHours } = addCompany
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { 
        token, parentId, type: 'Company', name, between, dir, 
        hours: setHours ? JSON.stringify(hours) : '{}'
      }

      setAddcompany({ ...addCompany, loading: true })

      addChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddcompany({ ...addCompany, show: false, name: '', errorMsg: "", loading: false })
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                case "nameTaken":
                  setAddcompany({ ...addCompany, errorMsg: "Can't enter the same company name twice" })
              }
            })
          }
        })
    } else {
      setAddcompany({ ...addCompany, errorMsg: "Please enter the company name" })
    }
  }
  const addTheTeam = () => {
    const { name } = addTeam
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { token, parentId, type: 'Team', name, between, dir }

      setAddteam({ ...addTeam, loading: true })

      addChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddteam({ ...addTeam, show: false, name: '', errorMsg: "", loading: false })
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                case "nameTaken":
                  setAddteam({ ...addTeam, errorMsg: "Can't enter the same team name twice" })
              }
            })
          }
        })
    } else {
      setAddteam({ ...addTeam, errorMsg: "Please enter the team name" })
    }
  }
  const addTheProject = () => {
    const { name, start, end, timeType } = addProject
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { token, parentId, type: 'Project', name, between, dir, startScheduled: 0, endScheduled: 0 }
      let validDate = false, errorMsg = ""

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"   
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Please select a due date"
          }
        }
      } else {
        validDate = true
      }

      if (validDate) {
        setAddproject({ ...addProject, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              cancelProject()
              setUpdatenumusages(true)
            }
          })
          .catch((err) => {
            if (err.status === 400) {
              err.json().then(({ status }) => {
                switch (status) {
                  case "nameTaken":
                    setAddteam({ ...addTeam, errorMsg: "Can't enter the same project name twice" })
                }
              })
            }
          })
      } else {
        setAddproject({ ...addProject, errorMsg })
      }
    } else {
      setAddproject({ ...addProject, errorMsg: "Please enter the project name" })
    }
  }
  const addTheWorker = () => {
    const { info } = addWorker
    const { parentId, between, dir } = addChoicebox

    if (info) {
      const data = { token, parentId, type: 'Member', workerId: info.id, between, dir }

      setAddworker({ ...addWorker, loading: true })

      addChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            cancelWorker()
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              if (status === "nameTaken") {
                setAddworker({ ...addWorker, errorMsg: "Cannot add the same worker", loading: false })
              }
            })
          }
        })
    } else {
      setAddworker({ ...addWorker, errorMsg: "Please enter the worker name", loading: false })
    }
  }
  const addTheTodolist = () => {
    const { name, list } = addTodolist
    let emptyTask = false, tasks = []
    let validDate = true

    for (let k = 0; k < list.length; k++) {
      let { header, start, end, timeType } = list[k]

      if (!header) {
        emptyTask = true

        break;
      }

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            tasks.push({
              header,
              startScheduled: getScheduledStamp(start),
              endScheduled: getScheduledStamp(end)
            })
          } else {
            if (start.calendar.selectedDate === 0) {
              setAddtodolist({ ...addTodolist, errorMsg: "One of the task's start date is not selected" })
            } else {
              setAddtodolist({ ...addTodolist, errorMsg: "One of the task's end date is not selected" })
            }

            validDate = false
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            tasks.push({
              header,
              startScheduled: 0,
              endScheduled: getScheduledStamp(end)
            })
          } else {
            setAddtodolist({ ...addTodolist, errorMsg: "One of the task's due date is not selected" })

            validDate = false
          }
        }
      } else {
        tasks.push({
          header,
          startScheduled: 0,
          endScheduled: 0
        })
      }
    }

    if (validDate) {
      const { workerId } = addChoicebox.data
      const { parentId, between, dir } = addChoicebox

      if (name && !emptyTask) {
        const data = { token, parentId, workerId: workerId ? workerId : null, type: 'Todolist', name, tasks: JSON.stringify(tasks), between, dir }
        
        setAddtodolist({ ...addTodolist, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setAddtodolist({ ...addTodolist, show: false, name: '', workerId: 0, list: [], errorMsg: "", loading: false })
              setUpdatenumusages(true)
            }
          })
      } else {
        setAddtodolist({ 
          ...addTodolist, 
          errorMsg: !name ? "Enter the name for the Todo list" : "There are one or more empty tasks"
        })
      }
    }
  }
  const addTheTask = () => {
    const { name, start, end, todolistId, timeType, otherData } = addTask
    const { workerId } = otherData
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { 
        token, parentId, workerId, type: 'Task', name, between, dir, 
        startScheduled: 0, endScheduled: 0
      }
      let validDate = false, errorMsg = ""

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Please select a due date"
          }
        }
      } else {
        validDate = true
        data.type = 'Schedule'
      }

      if (validDate) {
        setAddtask({ ...addTask, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              cancelTask()
              setUpdatenumusages(true)
            }
          })
      } else {
        setAddtask({ ...addTask, errorMsg })
      }
    } else {
      setAddtask({ ...addTask, errorMsg: "Please enter the name for the new task" })
    }
  }
  const unixToDate = unix => {
    const date = new Date(unix)
    const month = months[date.getMonth()]
    const theDate = date.getDate()
    let hour = date.getHours()
    let minute = date.getMinutes()
    const period = hour < 12 ? "am" : "pm"

    hour = hour > 12 ? hour - 12 : hour
    minute = minute < 10 ? "0" + minute : minute

    return month + " " + theDate + " @ " + hour + ":" + minute + " " + period
  }
  const postponeTheTask = id => {
    let data = {}, errorMsg = "", validDate = true

    if (!postponeTaskbox.show) {
      data["choiceId"] = id
    } else {
      const { name, start, end, timeType } = postponeTaskbox
      
      data = { choiceId: postponeTaskbox.id, name, startScheduled: 0, endScheduled: 0 }
      validDate = false

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Pelase select a due date"
          }
        }
      } else {
        validDate = true
      }
    }

    if (validDate) {
      data["type"] = !postponeTaskbox.show ? "get" : "update"
      data["token"] = token

      setPostponetaskbox({ ...postponeTaskbox, loading: true })

      postponeTask(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            if (!postponeTaskbox.show) {
              const newInfo = {...postponeTaskbox}
              const { name, startStamp, endStamp } = res

              createCalendar("", "postpone", { id, name }, { startStamp, endStamp })
            } else {
              cancelPostponeTask()
              getTheProgress()
            }
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                case "nameTaken":
                  setPostponetaskbox({ ...postponeTaskbox, errorMsg: "Can't enter the same task name twice" })

                  break;
              }
            })
          }
        })
    } else {
      setPostponetaskbox({ ...postponeTaskbox, errorMsg })
    }
  }
  const addTheNotes = () => {
    const { parentId } = addNote
    let bullets = [], { type, paragraphs } = addNote

    if (type === "Bullet") {
      addNote.bullets.forEach(function (bullet) {
        bullets.push(bullet.value)
      })
    }
    
    const data = { token, parentId, type: addNote.type, bullets: JSON.stringify(bullets), paragraphs }

    addNotes(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setAddnote({ ...addNote, show: false, type: '', bullets: [], paragraphs: [] })
          getTheChoices()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const addNewNote = () => {
    const { bullets } = addNote
    
    for (let k = 0; k < bullets.length; k++) {
      if (bullets[k].value === '') {
        setErrormsg("There are still empty bullet points")
        
        return
      }
    }

    bullets.push({
      editing: true,
      value: ''
    })

    setAddnote({ ...addNote, bullets })
  }
  const createCalendar = (
    dir = "", // "", next, previous
    type, // todolist, task, postpone
    info, // (index of todolist) or (name of task) or (start/end calendar for project)
    oldstamp // scheduled task for > 0
  ) => {
    const calcCalendar = (dir, newInfo, oldstamp) => {
      const days = { "Sun": 0, "Mon": 1, "Tue": 2, "Wed": 3, "Thu": 4, "Fri": 5, "Sat": 6 }
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let newCalendar, newTime, scheduled = 0

      if (oldstamp != null && !isNaN(oldstamp) && oldstamp > 0) {
        date = new Date(oldstamp)
      }

      let { monthNum, selectedDate, yearNum } = newInfo.calendar
      newTime = newInfo.time

      if (dir === "") { // no direction, just opened calendar
        monthNum = m
        yearNum = y

        newTime.hour = date.getHours() < 10 ? 
          "0" + date.getHours() 
          : 
          (
            date.getHours() > 12 ? (
              date.getHours() - 12 < 10 ? 
                "0" + (date.getHours() - 12) 
                : 
                date.getHours() - 12
            ) 
            : 
            date.getHours()
          )
        newTime.minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        newTime.period = date.getHours() > 11 ? "pm" : "am"

        if ((type === "todolist" && scheduled > 0) || oldstamp != null) {
          m = date.getMonth()
          y = date.getFullYear()

          monthNum = m
          yearNum = y
        }
      } else {
        if (dir === "next") {
          monthNum++

          if (monthNum > 11) {
            monthNum = 0
            yearNum++
          }
        } else {
          monthNum--

          if (monthNum < 0) {
            monthNum = 11
            yearNum--
          }
        }

        m = monthNum
        y = yearNum
      }

      let firstDay = new Date(y, m, 1), day, year
      let lastDay = new Date(y, m + 1, 0);
      let dateNum = 0

      firstDay = firstDay.toString().split("GMT")
      lastDay = lastDay.toString().split("GMT")

      firstDay = firstDay[0].split(" ")
      day = firstDay[0]
      year = firstDay[3]

      lastDay = lastDay[0].split(" ")
      lastDay.pop()
      lastDay.pop()
      lastDay.pop()

      firstDay = days[day]
      lastDay = parseInt(lastDay[2])

      newInfo.calendar.header = months[m] + " " + y
      newInfo.calendar.dates.forEach(function (info, rowIndex) {
        info.row.forEach(function (date, dateIndex) {
          info.row[dateIndex]["key"] = "calendar-row-" + rowIndex
          info.row[dateIndex]["date"] = -1

          if (rowIndex === 0) {
            if (dateIndex >= firstDay) {
              dateNum++

              info.row[dateIndex]["date"] = dateNum
              info.row[dateIndex]["unix"] = Date.parse(day + ", " + months[monthNum] + " " + dateNum + ", " + year)
            }
          } else if (dateNum < lastDay) {
            dateNum++

            info.row[dateIndex]["date"] = dateNum
            info.row[dateIndex]["unix"] = Date.parse(day + ", " + months[monthNum] + " " + dateNum + ", " + year)
          }
        })
      })
      newInfo.calendar.selectedDate = dir === "" ? date.getDate() : 0
      newInfo.calendar.monthNum = monthNum
      newInfo.calendar.yearNum = yearNum

      newTime.hour = date.getHours() < 10 ? 
        date.getHours() === 0 ? 
          "12"
          :
          "0" + date.getHours() 
        : 
        (
          date.getHours() > 12 ? (
            date.getHours() - 12 < 10 ? 
              "0" + (date.getHours() - 12) 
              : 
              date.getHours() - 12
          ) 
          : 
          date.getHours()
        )
      newTime.minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      newTime.period = date.getHours() > 11 ? "pm" : "am"
      newInfo.time = newTime

      return newInfo
    }

    let newInfo

    switch (type) {
      case "project":
        let newAddproject = {...addProject}

        newAddproject.startEnabled = false
        newAddproject.endEnabled = false

        if (dir === "") { // opening calendar
          if (info.timeType) {
            if (info.timeType === "startenddatetime") {
              newAddproject.start = calcCalendar(dir, newAddproject.start, oldstamp)
              newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)

              newAddproject.startEnabled = true
            } else {
              newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)
            }

            newAddproject.endEnabled = true
            newAddproject.timeType = info.timeType
          }
        } else {
          if (info === "start") {
            newAddproject.start = calcCalendar(dir, newAddproject.start, oldstamp)
            newAddproject.startEnabled = true
          } else {
            newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)
            newAddproject.startEnabled = true
          }
        }

        setAddproject(newAddproject)

        break;
      case "todolist": // info is index of todolist
        const newAddtodolist = {...addTodolist}

        newInfo = newAddtodolist.list[info.index]

        if (dir === "") { // opening calendar
          newInfo.startEnabled = false
          newInfo.endEnabled = false

          if (info.timeType) {
            if (info.timeType === "startenddatetime") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)

              newInfo.startEnabled = true
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
            }

            newInfo.endEnabled = true
            newInfo.timeType = info.timeType

            newAddtodolist.list[info.index] = newInfo
          } else {
            if (info === "start") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
              newInfo.startEnabled = true
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
              newInfo.endEnabled = true
            }
          }
        } else {
          if (newInfo.timeType === "startenddatetime") {
            if (info.type === "start") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
            }
          } else {
            newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
          }
        }

        setTimeinfo({
          ...newInfo,
          index: info.index,
          show: true
        })

        break;
      case "task":
        let newTask = {...addTask}
        let start, end

        if (dir === "") {
          newTask.start = calcCalendar(dir, newTask.start, oldstamp)
          newTask.end = calcCalendar(dir, newTask.end, oldstamp)
          newTask.timeType = info.timeType
        } else {
          if (info === "start") {
            newTask.start = calcCalendar(dir, newTask.start, oldstamp)
          } else {
            newTask.end = calcCalendar(dir, newTask.end, oldstamp)
          }
        }

        newTask.show = true

        setAddtask(newTask)

        break;
      case "postpone": // info is name of task
        let newPostponetaskbox = {...postponeTaskbox}

        if (dir === "") {
          newPostponetaskbox.id = info.id
          newPostponetaskbox.name = info.name

          if (oldstamp.startStamp > 0 || oldstamp.endStamp > 0) {
            if (oldstamp.startStamp > 0) {
              newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, oldstamp.startStamp)
            }

            if (oldstamp.endStamp > 0) {
              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, oldstamp.endStamp)
            }

            if (oldstamp.startStamp > 0 && oldstamp.endStamp > 0) {
              newPostponetaskbox.timeType = "startenddatetime"
            } else {
              newPostponetaskbox.timeType = "duedatetime"
            }
          } else {
            if (info.timeType === "startenddatetime") {
              newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, 0)
              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, 0)

              newPostponetaskbox.timeType = "startenddatetime"
            } else if (info.timeType === "duedatetime") {
              newPostponetaskbox.timeType = "duedatetime"

              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, 0)
            }
          }
        } else {
          if (info === "start") {
            newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, oldstamp)
          } else {
            newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, oldstamp)
          }
        }

        newPostponetaskbox.show = true

        setPostponetaskbox(newPostponetaskbox)

        break;
      default:
    }
  }
  const adjustDueTime = (
    timeType, 
    dir = null, 
    actionType, 
    type, 
    info // (index of task in todolist) or (start/end of project)
  ) => {
    const calcTime = newTime => {
      let { hour, minute, period } = newTime

      hour = parseInt(hour)
      minute = parseInt(minute)

      if (timeType === "hour") {
        hour = dir === "up" ? hour + 1 : hour - 1

        if (hour > 12) {
          hour = 1
        } else if (hour === 0) {
          hour = 12
        }
      } else if (timeType === "minute") {
        minute = dir === "up" ? minute + 1 : minute - 1

        if (minute > 59) {
          minute = 0
        } else if (minute === -1) {
          minute = 59
        }
      } else {
        period = period === "am" ? "pm" : "am"
      }

      newTime.hour = hour < 10 ? "0" + hour : hour
      newTime.minute = minute < 10 ? "0" + minute : minute
      newTime.period = period

      return newTime
    }

    let newTime

    switch (type) {
      case "project":
        const newAddproject = {...addProject}

        if (info === "start") {
          newTime = newAddproject.start.time
        } else {
          newTime = newAddproject.end.time
        }
        
        newTime = calcTime(newTime)

        if (info === "start") {
          newAddproject.start.time = newTime
        } else {
          newAddproject.end.time = newTime
        }

        setAddproject(newAddproject)

        break;
      case "todolist":
        const newAddtodolist = {...addTodolist}

        if (info.type === "start") {
          newTime = newAddtodolist.list[info.index].start.time
        } else {
          newTime = newAddtodolist.list[info.index].end.time
        }

        newTime = calcTime(newTime)

        if (info.type === "start") {
          newAddtodolist.list[info.index].start.time = newTime
        } else {
          newAddtodolist.list[info.index].end.time = newTime
        }

        setAddtodolist(newAddtodolist)

        break;
      case "task":
        const newAddtask = {...addTask}

        if (info === "start") {
          newTime = newAddtask.start.time
        } else {
          newTime = newAddtask.end.time
        }
        
        newTime = calcTime(newTime)

        if (info === "start") {
          newAddtask.start.time = newTime
        } else {
          newAddtask.end.time = newTime
        }

        setAddtask(newAddtask)

        break;
      case "postpone":
        const newPostponetaskbox = {...postponeTaskbox}

        if (info === "start") {
          newTime = newPostponetaskbox.start.time
        } else {
          newTime = newPostponetaskbox.end.time
        }

        newTime = calcTime(newTime)

        if (info === "start") {
          newPostponetaskbox.start.time = newTime
        } else {
          newPostponetaskbox.end.time = newTime
        }

        setPostponetaskbox(newPostponetaskbox)

        break;
      default:

    }
  }
  const deleteNote = index => {
    const { bullets } = addNote

    bullets.splice(index, 1)

    setAddnote({ ...addNote, bullets })
  }
  const removeTheParent = id => {
    const data = { token, id }

    removeParent(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          getTheChoices()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const moveInTheBetween = info => {
    const { parentId } = info
    const { id } = movingChoice.data
    const data = { token, newParentId: parentId, newChild: info.id, movingId: id }

    moveInBetween(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setMovingchoice({ ...movingChoice, active: false, data: null })
          getTheChoices()
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          
        }
      })
  }
  const moveTheChoice = (data, rowIndex) => {
    if (!movingChoice.active) {
      const newData = { ...data, rowIndex }

      setMovingchoice({ ...movingChoice, active: true, data: newData })
    } else {
      const { choiceId, type, parentId } = movingChoice.data
      let info = { token, type, id: choiceId, newId: data.choiceId }
      let valid = true

      if (
        (type === "Task" && data.type !== "Todolist")
        ||
        (type === "Note" && data.type === "Todolist")
        ||
        (choiceId == data.choiceId)
        ||
        rowIndex > movingChoice.data.rowIndex
      ) {
        valid = false
      }

      if (valid === true) {
        setMoveloading(true)

        moveChoice(info)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setMovingchoice({ ...movingChoice, active: false, data: null })

              if (data.type === "Member") {
                getTheProgress()
              } else {
                getTheChoices()
              }
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      }
    }
  }
  const validChoiceMove = (data, rowIndex) => { // data to be replace by movingChoice.data
    const { choiceId, type, parentId, datas = null } = movingChoice.data

    return ( // list all allows inside
      data.choiceId != parentId
      &&
      data.choiceId != choiceId
      &&
      levelsDict[data.type].allowed.includes(levelsDict[type].index)
    )
  }
  const validBetweenChoiceMove = (data, rowIndex) => {
    const { choiceId, type, parentId, datas = null } = movingChoice.data

    return !(
      data.choiceId === choiceId
      ||
      levelsDict[data.type].allowed.includes(levelsDict[type].index)
    )
  }
  const checkTheValidBetween = (parentId, data, between, dir) => {
    const { id } = data
    const info = { token, id }

    checkValidBetween(info)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { parent, self } = res

          setAddchoicebox({ ...addChoicebox, show: true, parentId: data.choiceId, data: { parent, self }, between: true, dir })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const deleteTheChoice = (id, type, name) => {
    if (!deleteChoicebox.show) {
      setDeletechoicebox({ ...deleteChoicebox, show: true, id, type, name })
    } else {
      const data = { token, id: deleteChoicebox.id }

      setDeletechoicebox({ ...deleteChoicebox, loading: true })

      deleteChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setDeletechoicebox({ ...deleteChoicebox, show: false, id: 0, type: '', name: '', loading: false })
            getTheProgress()
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const completedTheTask = (id, index) => {
    const data = { token, id }

    completedTask(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          getTheProgress()
        }
      })
  }
  const seeTheNotes = id => {
    const data = { token, id }

    seeNotes(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { notes } = res

          setSeenotesbox({
            ...seeNotesbox,
            show: true,
            header: res.noteHeader,
            type: notes["type"],
            content: notes["content"]
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const searchTheWorkers = name => {
    const data = { token, name }

    searchWorkers(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setAddworker({ ...addWorker, list: res.workers, showList: res.workers.length > 0 ? true : false })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const displayDatas = (parentId, datas, start, rowIndex) => {
    if (datas) {
      return (
        <div className="top-row">
          {datas.map((data, index) => {
            return (
              <div key={data.key} style={{ margin: data.type === "Todolist" ? '0 5px 10px 5px' : '0 5px' }}>
                {!start && (
                  <div className={"divide" + (
                    movingChoice.active ? 
                      validBetweenChoiceMove(data, rowIndex) ? "-hover" : ""
                      :
                      ""
                  )} onClick={(e) => {
                    if (!movingChoice.active) {
                      checkTheValidBetween(parentId, data, true, "")

                      e.stopPropagation()
                    } else {
                      if (validBetweenChoiceMove(data, rowIndex)) {
                        moveInTheBetween(data)

                        e.stopPropagation()
                      }
                    }
                  }}>
                    <div className="delete-icon" onClick={() => removeTheParent(data.choiceId)}><IoMdClose style={{ height: '60%', width: '60%' }}/></div>
                    <div className="arrow-icon"><FaArrowDownLong style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                  </div>
                )}

                <div className="row">
                  <div className="top-icons">
                    <div className="row">
                      {(!addedBy || (addedBy && data.notesIncluded)) && (
                        <div className="top-icon" style={{ padding: '0 5px' }} onClick={(e) => {
                          if (data.notesIncluded) {
                            seeTheNotes(data.choiceId)
                          } else {
                            setAddnote({ ...addNote, show: true, parentId: data.choiceId, parentName: data.name })

                            e.stopPropagation()
                          }
                        }}>
                          <div className="column"><CgNotes style={{ color: 'black', height: '80%', width: '80%' }}/></div>
                          <div className="column"><div className="top-icon-header">{data.notesIncluded ? 'SEE NOTE' : '+ ADD NOTE'}</div></div>
                        </div>
                      )}

                      {(!movingChoice.active || movingChoice.data.choiceId === data.choiceId) && (
                        <div className="top-icon" style={{ padding: '0 5px' }} onClick={(e) => {
                          if (!movingChoice.active) {
                            moveTheChoice(data, rowIndex)
                          } else {
                            setMovingchoice({ ...movingChoice, active: false, data })
                          }

                          e.stopPropagation()
                        }}>
                          <div className="column"><BiTransfer style={{ color: 'black', height: '80%', transform: 'rotate(-45deg)', width: '80%' }}/></div>
                          <div className="column"><div className="top-icon-header">MOVE</div></div>
                        </div>
                      )}

                      {!addedBy && (
                        <div className="top-icon" onClick={() => deleteTheChoice(data.choiceId, data.type, data.name)}>
                          <IoMdClose style={{ color: 'black', height: '80%', width: '80%' }}/>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={"info-box" + (
                    movingChoice.active ? 
                      validChoiceMove(data, rowIndex) ? "-hover" : ""
                      :
                      ""
                  )}
                  style={data.type === "Todolist" ? { minWidth: 200 } : {
                    margin: !data.datas ? 
                      '0 10px 50px 10px' // has children
                      :
                      '0 auto', // no children
                    width: 'auto'
                  }}
                  onClick={(e) => {
                    if (movingChoice.active) {
                      if (
                        validChoiceMove(data, rowIndex)
                      ) {
                        moveTheChoice(data, rowIndex)

                        e.stopPropagation()
                      }
                    }
                  }}
                >
                  <div className="row">
                    <div className="info-headers">
                      <div className="icon-holder">
                        {data.type.toLowerCase() === "company" && <MdOutlineCorporateFare style={{ color: 'black', height: '70%', width: '70%' }}/>}
                        {data.type.toLowerCase() === "team" && <IoIosPeople style={{ color: 'black', height: '80%', width: '80%' }}/>}
                        {data.type.toLowerCase() === "project" && <AiFillProject style={{ color: 'black', height: '70%', width: '70%' }}/>}
                        {data.type.toLowerCase() === "member" && <GrUserWorker style={{ color: 'black', height: '60%', width: '60%' }}/>}
                        {data.type.toLowerCase() === "todolist" && <CiViewList style={{ color: 'black', height: '80%', width: '80%' }}/>}
                      </div>
                      <div className="info-header">
                        <div className="info-header-title">{data.type === "Todolist" ? 'TO-DO LIST' : data.type.toUpperCase()}</div>
                        <div className="info-header-name">{data.name}</div>
                      </div>
                    </div>
                  </div>

                  {(data.type === "Todolist" && data.datas) && data.datas.map((data, index) => {
                    return (
                      <div key={data.key} className="todo-item">
                        <div className="todo-item-header" onClick={() => viewTheTaskInfo(data.choiceId)}>{index + 1}: <strong>{data.name}</strong></div>
                        <div className="todo-item-actions">
                          <div className="todo-item-action" onClick={() => completedTheTask(data.choiceId, null)}>
                            <FaCircleCheck style={{ color: data.completed ? 'green' : 'lightgrey', height: '100%', width: '100%' }}/>
                          </div>

                          {data.setAction && (
                            <>
                              <div className="todo-item-action" onClick={() => deleteTheChoice(data.choiceId, "Task", data.name)}>
                                <MdOutlineClose style={{ color: 'black', height: '100%', width: '100%' }}/>
                              </div>

                              {(!movingChoice.active || movingChoice.data.choiceId === data.choiceId) && (
                                <div className="todo-item-action" onClick={(e) => {
                                  if (!movingChoice.active) {
                                    moveTheChoice(data, rowIndex)
                                  } else { // record moving choice
                                    setMovingchoice({ ...movingChoice, active: false, data })
                                  }

                                  e.stopPropagation()
                                }}>
                                  <BiTransfer style={{ color: 'black', height: '100%', transform: 'rotate(-45deg)', width: '100%' }}/>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )
                  })}

                  {!addedBy && (
                    data.type === "Todolist" ? 
                      <div className="row">
                        <div className="todolist-add" onClick={() => {
                          const json = { token, dataType: "Task" }

                          isExceedUsage(json)
                            .then((res) => {
                              if (res.ok) {
                                return res.json()
                              }
                      
                              throw res
                            })
                            .then((res) => {
                              if (res) {
                                const { msg } = res
                      
                                if (msg.includes("false") && msg.split(":")[2] === "false") {
                                  setAddtask({ ...addTask, show: true, timeType: '', otherData: { parentId: data.choiceId, workerId: data.workerId } })
                                } else {
                                  setAddchoicebox({ ...addChoicebox, show: false })
                                  setShowexceedusagebox({ ...showExceedusagebox, show: true })
                                }
                              }
                            })
                        }}>
                          <div className="todolist-add-header">Add task</div>
                          <div className="todolist-add-icon">
                            <IoIosAdd style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="add-actions" style={{ justifyContent: data.datas ? 'space-between' : 'space-around' }}>
                        {!movingChoice.active && (
                          <>
                            {data.datas && data.datas.length > 0 && (
                              <div className="add-action-left" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: data.choiceId, data, between: false, dir: "before" })}>
                                <IoIosAdd style={{ color: 'white', height: '100%', width: '100%' }}/>
                              </div>
                            )}

                            {(!data.datas) && ( // middle
                              <div className="add-action-middle" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: data.choiceId, data, between: false, dir: "after" })}>
                                <IoIosAdd style={{ color: 'white', height: '100%', width: '100%' }}/>
                              </div>
                            )}

                            {data.datas && data.datas.length > 0 && (
                              <div className="add-action-right" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: data.choiceId, data, between: false, dir: "after" })}>
                                <IoIosAdd style={{ color: 'white', height: '100%', width: '100%' }}/>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                  )}
                </div>

                {(data.type != "Todolist" && data.datas) && displayDatas(data.choiceId, data.datas, false, rowIndex + 1)}
              </div>
            )
          })}
        </div>
      );
    }
  }
  const adjustTime = (choiceType, index, type, timeType, dir) => {
    let newTimespan = null

    switch (choiceType) {
      case "company":
        newTimespan = {...addCompany.hours}

        break;
      case "member":
        newTimespan = {...addNewmember.workingHours}

        break;
      default:
    }

    Object.keys(newTimespan).forEach(function (time, timeIndex) {
      if (timeIndex === index) {
        let { hour, minute, period } = newTimespan[time][type]

        hour = parseInt(hour)
        minute = parseInt(minute)

        switch (timeType) {
          case "hour":
            if (dir === "up") {
              hour = hour >= 12 ? hour - 12 : hour + 1
            } else {
              hour = hour === 0 ? 12 : hour - 1
            }

            break;
          case "minute":
            if (dir === "up") {
              minute = minute === 59 ? 0 : minute + 1
            } else {
              minute = minute === 0 ? 59 : minute - 1
            }

            break;
          case "period":
            period = period === "am" ? "pm" : "am"

            break;
          default:

        }

        newTimespan[time][type]["hour"] = hour
        newTimespan[time][type]["minute"] = minute
        newTimespan[time][type]["period"] = period

        switch (choiceType) {
          case "company":
            setAddcompany({ ...addCompany, hours: newTimespan })
    
            break;
          case "member":
            setAddnewmember({ ...addNewmember, workingHours: newTimespan })
    
            break;
          default:
        }
      }
    })
  }
  const addTheNewMember = () => {
    const { firstName, lastName, email, workingHours } = {...addNewmember}
    const data = { token, firstName, lastName, email, workingHours: JSON.stringify(workingHours) }

    setAddnewmember({ ...addNewmember, loading: true })

    addNewMember(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setAddnewmember({ ...addNewmember, show: false, loading: false, errorMsg: "" })
          getTheChoices()
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "emailAlreadyUsed":
                setAddnewmember({ ...addNewmember, errorMsg: "E-mail already registered" })

                break;
              case "emptyFirstname":
                setAddnewmember({ ...addNewmember, errorMsg: "Please enter their first name" })

                break;
              case "emptyLastname":
                setAddnewmember({ ...addNewmember, errorMsg: "Please enter their last name" })

                break;
              case "emptyEmail":
                setAddnewmember({ ...addNewmember, errorMsg: "Please enter their e-mail" })

                break;
              default:
            }
          })
        }
      })
  }
  const viewTheTaskInfo = taskId => {
    const data = { token, taskId }

    viewTaskInfo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeetaskinfobox({
            ...seeTaskinfobox,
            show: true,
            info: res.taskInfo,
            id: taskId,
            comments: res.comments,
            parents: res.parents
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const postTheComment = () => {
    const { id, comment } = seeTaskinfobox
    const data = { token, taskId: id, comment }

    postComment(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeetaskinfobox({
            ...seeTaskinfobox,
            comments: res.comments,
            comment: ''
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const deleteTheComment = (id, index) => {
    const data = { token, commentId: id }

    deleteComment(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const newComments = seeTaskinfobox.comments

          newComments.splice(index, 1)

          setSeetaskinfobox({ ...seeTaskinfobox, comments: newComments })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const saveTheComment = (id, index) => {
    const newComments = [...seeTaskinfobox.comments]
    const data = { token, commentId: id, newComment: newComments[index].info }

    saveComment(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          newComments[index].editing = false

          setSeetaskinfobox({ ...seeTaskinfobox, comments: newComments })
        }
      })
  }
  const requestTheFeature = () => {
    if (!requestFeaturebox.show) {
      setRequestfeaturebox({
        ...requestFeaturebox,
        show: true,
        input: ''
      })
    } else {
      const { input, isPrivate } = requestFeaturebox

      if (input) {
        const data = { token, input, isPrivate }

        setRequestfeaturebox({ ...requestFeaturebox, loading: true })

        requestFeature(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setRequestfeaturebox({
                ...requestFeaturebox,
                show: false,
                input: '',
                loading: false,
                errorMsg: ''
              })
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      } else {
        setRequestfeaturebox({
          ...requestFeaturebox,
          errorMsg: "Request is empty"
        })
      }
    }
  }

  // cancel choices
  const cancelCompany = () => {
    setAddcompany({ ...addCompany, show: false, name: '', setHours: false, loading: false, errorMsg: '' })
  }
  const cancelTeam = () => {
    setAddteam({ ...addTeam, show: false, name: '', loading: false, errorMsg: '' })
  }
  const cancelProject = () => {
    const newAddproject = {...addProject}
    const { start, end } = newAddproject

    start.calendar.selectedDate = 0
    end.calendar.selectedDate = 0

    setAddproject({ 
      ...addProject, show: false, name: '', timeType: '',
      start, end
    })
    setErrormsg('')
  }
  const cancelWorker = () => {
    setAddworker({ ...addWorker, show: false, name: '', errorMsg: "", loading: false, noMembers: false })
  }
  const cancelTodolist = () => {
    setAddtodolist({ ...addTodolist,
      show: false,
      name: "",
      list: [],
      workerId: 0,
      errorMsg: "",
      loading: false
    })
  }
  const cancelTask = () => {
    setAddtask({ ...addTask, show: false, timeType: '', name: '', loading: false, errorMsg: '' })
  }
  const cancelPostponeTask = () => {
    setPostponetaskbox({ ...postponeTaskbox, show: false, id: 0, timeType: '', loading: false, errorMsg: '' })
  }

  const logoutUser = () => {
    localStorage.clear()
    
    window.location = "/login"
  }

  useEffect(() => {
    if (isMobile) {
      window.location = "/"
    } else {
      if (localStorage.getItem("token")) {
        setToken(localStorage.getItem("token"))
        setAddedby(localStorage.getItem("addedBy"))
      } else {
        window.location = "/"
      }
    }
  }, [])

  useEffect(() => {
    if (token) getTheUserInfo()
  }, [token])

  useEffect(() => {
    if (addWorker.show) {
      searchTheWorkers('')
    }
  }, [addWorker.show])

  useEffect(() => {
    if (addTodolist.workerId > 0) {
      createCalendar("", "task", null, 0)
    }
  }, [addTodolist.workerId])

  useEffect(() => {
    if (addTodolist.list.length > 0) {
      let listDiv = document.getElementById("todolist-list")

      listDiv.scrollTop = listDiv.scrollHeight
    }
  }, [addTodolist.list.length])

  useEffect(() => {
    if (seeTaskinfobox.show) {
      const info = document.getElementById("see-task-info-comments")

      info.scrollTop = info.scrollHeight
    }
  }, [seeTaskinfobox.show])

  useEffect(() => {
    if (updateNumusages) {
      getTheUsage()
      getTheProgress()
    }
  }, [updateNumusages])

  if (localStorage.getItem("token"))
    return (
      <div id="dashboard-page" onClick={() => {
        if (notification.show) {
          getTheNotifications('num')
        }
        
        setMovingchoice({ ...movingChoice, active: false })
        setNotification({ ...notification, show: false })
        setUsagerecords({ ...usageRecords, show: false })
      }}>
        <div id="dashboard-page-web">
          <div id="dashboard-main">
            <div id="progress">
              <div id="progress-header">YOUR TASK(S)</div>

              {!progressLoading ? 
                progress.length > 0 ? 
                  <div id="progress-list">
                    {progress.map((info, index) => (
                      <div className="progress" key={info.key}>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                            {info.setAction && (
                              <>
                                <div className="progress-header-action" onClick={() => deleteTheChoice(info.id, "Task", info.taskName)}>
                                  <IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/>
                                </div>
                                <div className="progress-header-action" onClick={() => postponeTheTask(info.id)}>
                                  <FiEdit2 style={{ color: 'white', height: '100%', width: '100%' }}/>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="progress-task-header" onClick={() => viewTheTaskInfo(info.id)}>
                          {info.self ? 
                            <><strong>Your task</strong>: {info.taskName}</>
                            :
                            info.workerName.map((eachInfo, index) => <><strong key={"worker-" + index}>{eachInfo.firstName}'s task:</strong> {info.taskName}</>)
                          }
                        </div>

                        {(info.endStamp && info.endStamp != 0) && (
                          info.startStamp != 0 ? // start and end
                            <>
                              <div className="progress-header" style={{ margin: '10px 0' }}>
                                <strong>Scheduled from</strong><br/>{info.start} <br/><strong>to</strong><br/> {info.end}
                              </div>
                              <div className="progress-header">
                                <strong>Due in:</strong> {info.endStamp}
                              </div>
                            </>
                            :
                            <>
                              <div className="progress-header">
                                <strong>Due in:</strong> {info.endStamp} <strong>on</strong> {info.end}
                              </div>
                            </>
                        )}

                        <div className="progress-header"><strong>Created on:</strong> {info.created}</div>

                        {info.setComplete && (
                          <div className="progress-actions">
                            <div 
                              className={"progress-action" + (info.completed ? "-completed" : "")}
                              onClick={() => completedTheTask(info.id, index)}
                            >
                              <div className="column"><div className="progress-action-header">Completed</div></div>
                              <div className="column">
                                <div className="progress-action-icon">
                                  <FaCircleCheck style={{ color: info.completed ? 'green' : 'black', height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  :
                  <div id="progress-no-result">
                    {addedBy ? 
                      'You will see your task(s) here'
                      :
                      "You haven't added any task(s)"
                    }
                  </div>
                :
                <div id="loading-container" style={{ height: '50%' }}>
                  <div id="loading-icon" style={{ height: 30, width: 30 }}>
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              }
            </div>
            <div id="main">
              <Mainheader
                numUsages={numUsages}
                name={name}
                numNotifications={numNotifications}
                addNewMember={() => setAddnewmember({ 
                  ...addNewmember, 
                  show: true, 
                  firstName: process.env.REACT_APP_PROD === "prod" ? "" : "", 
                  lastName: process.env.REACT_APP_PROD === "prod" ? "" : "", 
                  email: process.env.REACT_APP_PROD === "prod" ? "" : "",
                  setHours: false,
                  workingHours: {
                    "Sun": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Mon": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Tue": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Wed": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Thu": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Fri": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                    "Sat": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } }
                  },
                  loading: false,
                  errorMsg: ""
                })}
                logout={() => logoutUser()}
                getTheUsage={() => getTheUsage()}
                getTheNotifications={() => getTheNotifications()}
                getTheUsageRecords={() => getTheUsageRecords()}
                theReport={() => setReport(true)}
                requestFeature={() => requestTheFeature()}
                isNotificationOpen={notification.show}
                showControls={true}
              />
              <div id="dashboard">
                {!dataLoading ? 
                  <>
                    {(datas.length > 0 && !addedBy) && (
                      <div className="row">
                        <div id="add-button" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: 0, data: { type: 'Company' }, between: false })}>
                          Add
                          <div className="column"><div id="add-button-icon"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div></div>
                        </div>
                      </div>
                    )}
                    {datas.length > 0 ? 
                      <div id="data-container" 
                        style={{ 
                          transform: 'scale(' + scale + ')',
                        }}
                      >
                        {displayDatas(null, datas, true, 0)}
                      </div>
                      :
                      !addedBy ? 
                        <div id="options">
                          <div id="options-header">Start with a</div>

                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Member' }, between: false })
                            setAddworker({ ...addWorker, show: true })
                          }}>
                            <div className="column"><div className="option-header">A user</div></div>
                            <div className="option-icon">
                              <GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>

                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Project' }, between: false })
                            setAddproject({ ...addProject, show: true })
                          }}>
                            <div className="column"><div className="option-header">A project</div></div>
                            <div className="option-icon">
                              <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>

                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Team' }, between: false })
                            setAddteam({ ...addTeam, show: true })
                          }}>
                            <div className="column"><div className="option-header">A team</div></div>
                            <div className="option-icon">
                              <IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>

                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Company' }, between: false })
                            setAddcompany({ ...addCompany, show: true })
                          }}>
                            <div className="column"><div className="option-header">A company</div></div>
                            <div className="option-icon">
                              <MdOutlineCorporateFare style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                        :
                        <div id="addedby-container">
                          <div id="addedby-header">You will see your work here</div>
                        </div>
                    }
                  </>
                  :
                  <div id="loading-container" style={{ margin: '10px 0' }}>
                    <div id="loading-icon" style={{ height: 30, width: 30 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div id="footer"><Footer showLicense={() => setShowlicenses(true)}/></div>

          <div id="zoom-box">
            <div className={"zoom-action" + (!(!(scale < 1)) ? '' : '-disabled')} onClick={() => {
              if (scale < 1) {
                setScale(scale + 0.1)
              }
            }}>
              <div className="column">
                <div className="zoom-action-icon">
                  <FiZoomIn style={{ color: 'black', height: '50%', width: '50%' }}/>
                </div>
              </div>
            </div>
            <div className={"zoom-action" + (!(!(scale > 0)) ? '' : '-disabled')} onClick={() => setScale(scale - 0.1)}>
              <div className="column">
                <div className="zoom-action-icon">
                  <FiZoomOut style={{ color: 'black', height: '50%', width: '50%' }}/>
                </div>
              </div>
            </div>
          </div>

          {notification.show && (
            <div id="notification-box">
              <Notifications 
                notification={notification} 
                viewTaskInfo={(id) => viewTheTaskInfo(id)}
                seeNotes={(id) => seeTheNotes(id)}
              />
            </div>
          )}

          {(
            report
            ||
            introBox.show
            ||
            showInactiveplan
            ||
            addChoicebox.show || addCompany.show || addTeam.show || addProject.show || addWorker.show || addTodolist.show || addTask.show || postponeTaskbox.show || addNote.show
            ||
            addNewmember.show
            ||
            deleteChoicebox.show
            ||
            seeNotesbox.show
            ||
            seeTaskinfobox.show
            ||
            usageRecords.show
            ||
            showExceedusagebox.show
            ||
            requestFeaturebox.show
            ||
            showLicenses
          ) && (
            <div id="hidden" onClick={(e) => {
              setReport(false)
              setAddchoicebox({ ...addChoicebox, show: false, type: '' })
              cancelCompany()
              cancelTeam()
              cancelProject()
              cancelWorker()
              cancelTodolist()
              cancelTask()
              cancelPostponeTask()
              setAddnote({ ...addNote, show: false })
              setAddnewmember({ ...addNewmember, show: false })
              setDeletechoicebox({ ...deleteChoicebox, show: false })
              setSeenotesbox({ ...seeNotesbox, show: false })
              setSeetaskinfobox({ ...seeTaskinfobox, show: false })
              setUsagerecords({ ...usageRecords, show: false })
              setRequestfeaturebox({ ...requestFeaturebox, show: false, loading: false })
              setShowlicenses(false)
              setErrormsg('')
            }}>
              {report && <Report close={() => setReport(false)}/>}

              {(introBox.show) && (
                <div id="intro-box">
                  {introBox.step === 0 && (
                    <>
                      <div className="intro-header">
                        Yes! You're finally here!
                        <br/><br/>
                        Welcome to DETACH
                      </div>

                      <div>
                        <div className="intro-mini-header">
                          We keep your work organized<br/>so you can (detach) from it and relax
                        </div>

                        <div className="intro-image">
                          <img src="/beach.jpg"/>
                        </div>
                      </div>
                    </>
                  )}

                  {introBox.step === 1 && (
                    <>
                      <div className="intro-header">
                        Schedule your TASKS and PROJECTS<br/><br/>Create TODOLISTS, TEAMS, EMPLOYEES and COMPANIES<br/><br/> and
                        see everything ON-ONE-SCREEN<br/>without a single headache and confusion
                      </div>

                      <div className="row">
                        <div id="icons">
                          <div className="icon"><GoTasklist style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                          <div className="icon"><CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                          <div className="icon"><AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                          <div className="icon"><IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                          <div className="icon"><GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                          <div className="icon"><MdOutlineCorporateFare style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                        </div>
                      </div>
                    </>
                  )}

                  {introBox.step === 2 && (
                    <>
                      <div className="intro-header">A quick overview<br/>Creating a company, project and to-do list in 31 seconds</div>

                      <div id="demo">
                        <video src="/short_tutorials/web/overview.mp4" autoPlay style={{ height: '100%', width: '100%' }}/>
                      </div>
                    </>
                  )}

                  <div>
                    <div id="dots">
                      <div className={"dot" + (introBox.step === 0 ? "-selected" : "")}/>
                      <div className={"dot" + (introBox.step === 1 ? "-selected" : "")}/>
                      <div className={"dot" + (introBox.step === 2 ? "-selected" : "")}/>
                    </div>

                    <div id="actions">
                      <div className="action" onClick={() => {
                        const newStep = introBox.step - 1

                        if (introBox.step > 0) {
                          setIntrobox({ ...introBox, step: newStep })
                        }
                      }}><GrFormPrevious style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                      <div className="action" onClick={() => {
                        const newStep = introBox.step + 1

                        if (introBox.step < 2) {
                          setIntrobox({ ...introBox, step: newStep })
                        }
                      }}><GrFormNext style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    </div>

                    {introBox.step === 2 && <div id="intro-done" onClick={() => readedTheIntro()}>Start Using</div>}
                  </div>
                </div>
              )}
              {(showInactiveplan) && (
                <div id="inactive-plan-box">
                  <div id="inactive-plan-logo">
                    <img src="/logo.png"/>
                  </div>

                  <div id="inactive-plan-header">
                    Your subscription is inactive. Please resume your plan to continue
                    using Detach
                  </div>

                  <div id="inactive-plan-button" onClick={() => window.location = "/pricing"}>Ok</div>
                </div>
              )}

              {(
                addChoicebox.show || addNote.show
              ) && (
                <div id="add-box" onClick={(e) => e.stopPropagation()}>
                  <div id="add-headers">
                    {(
                      (
                        !addChoicebox.show
                        &&
                        (
                          addCompany.show || addTeam.show || addProject.show || addWorker.show || addTodolist.show || addTask.show
                        )
                      )
                    ) && (
                      <div onClick={(e) => {
                        e.stopPropagation()

                        setAddnote({ ...addNote, show: false })
                        setAddchoicebox({ ...addChoicebox, show: true })
                      }}><FaArrowLeft /></div>
                    )}

                    <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>
                      {addChoicebox.show && 'What do you want to create'}
                      {addNote.show && (
                        'Add a ' + (
                          addNote.type === "Bullet" ? 'to-do list' : 'note'
                        ) + ' for ' + addNote.parentName
                      )}
                    </div>
                  </div>

                  {addChoicebox.show && (
                    <div id="options" style={{ width: '100%' }}>
                      {addChoicebox.data.parent ? 
                        (
                          4 > levelsDict[addChoicebox.data.parent].index
                          &&
                          4 < levelsDict[addChoicebox.data.self].index
                        ) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Todolist")
                          }}>
                            <div className="column"><div className="option-header">A todo list</div></div>
                            <div className="option-icon">
                              <CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                        :
                        (levelsDict[addChoicebox.data.type].allowed.includes(4)) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Todolist")
                          }}>
                            <div className="column"><div className="option-header">A todo list</div></div>
                            <div className="option-icon">
                              <CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                      }

                      {addChoicebox.data.parent ? 
                        (
                          3 > levelsDict[addChoicebox.data.parent].index
                          &&
                          3 < levelsDict[addChoicebox.data.self].index
                        ) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Member")
                          }}>
                            <div className="column"><div className="option-header">A user</div></div>
                            <div className="option-icon">
                              <GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                        :
                        (levelsDict[addChoicebox.data.type].allowed.includes(3)) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Member")
                          }}>
                            <div className="column"><div className="option-header">A user</div></div>
                            <div className="option-icon">
                              <GrUserWorker style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                      }

                      {addChoicebox.data.parent ? 
                        (
                          2 > levelsDict[addChoicebox.data.parent].index
                          &&
                          2 < levelsDict[addChoicebox.data.self].index
                        ) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Project")
                          }}>
                            <div className="column"><div className="option-header">A project</div></div>
                            <div className="option-icon">
                              <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                        :
                        (levelsDict[addChoicebox.data.type].allowed.includes(2)) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Project")
                          }}>
                            <div className="column"><div className="option-header">A project</div></div>
                            <div className="option-icon">
                              <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                      }

                      {addChoicebox.data.parent ? 
                        (
                          1 > levelsDict[addChoicebox.data.parent].index
                          &&
                          1 < levelsDict[addChoicebox.data.self].index
                        ) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Team")
                          }}>
                            <div className="column"><div className="option-header">A team</div></div>
                            <div className="option-icon">
                              <IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                        :
                        levelsDict[addChoicebox.data.type].allowed.includes(1) && (
                          <div className="option" onClick={(e) => {
                            e.stopPropagation()

                            isTheExceedUsage("Team")
                          }}>
                            <div className="column"><div className="option-header">A team</div></div>
                            <div className="option-icon">
                              <IoIosPeople style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        )
                      }

                      {(!addChoicebox.data.parent && levelsDict[addChoicebox.data.type].allowed.includes(0)) && (
                        <div className="option" onClick={(e) => {
                          e.stopPropagation()

                          isTheExceedUsage("Company")
                        }}>
                          <div className="column"><div className="option-header">A company</div></div>
                          <div className="option-icon">
                            <MdOutlineCorporateFare style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {addNote.show && (
                    <div id="notes-list">
                      {addNote.type ? 
                        <div id="selected-notes-type">
                          {addNote.type === "Bullet" ? 
                            <>
                              <div className="row">
                                <div id="add-bullet-button" onClick={() => addNewNote()}>
                                  <div className="column"><div id="add-bullet-header">Add a note</div></div>
                                  <div className="column"><div id="add-bullet-icon"><IoAddCircleOutline style={{ height: '100%', width: '100%' }}/></div></div>
                                </div>
                              </div>

                              <div id="bullets-list">
                                {addNote.bullets.map((bullet, index) => (
                                  <div className="bullet-item" key={"bullet-note-" + index}>
                                    <div className="column"><div className="item-dot"/></div>
                                    <input className="item-input" placeholder="Enter a note" value={bullet.value} onChange={e => {
                                      const { bullets } = addNote

                                      bullets[index].value = e.target.value

                                      setAddnote({ ...addNote, bullets })
                                      setErrormsg('')
                                    }}/>
                                    
                                    {(addNote.bullets.length > 1 && index > 0) && <div className="column"><div className="item-delete" onClick={() => deleteNote(index)}><IoMdClose style={{ height: '100%', width: '100%' }}/></div></div>}
                                  </div>
                                ))}
                              </div>
                            </>
                            :
                            <div id="paragraph-input">
                              <textarea 
                                maxLength="500" 
                                placeholder="Enter some texts....."
                                onChange={e => setAddnote({ ...addNote, paragraphs: e.target.value })}
                              />
                            </div>
                          }
                        </div>
                        :
                        <div id="notes-type">
                          <div id="notes-type-header">Select the type of note you want</div>
                          <div className="row">
                            <div id="notes-type-list">
                              <div className="note-type" onClick={() => setAddnote({ ...addNote, type: 'Bullet', bullets: [{ editing: true, value: '' }] })}>
                                <div className="note-type-icon"><MdFormatListBulleted style={{ height: '100%', width: '100%' }}/></div>
                                <div className="note-type-header">List</div>
                              </div>
                              <div className="note-type" onClick={() => setAddnote({ ...addNote, type: 'Paragraphs' })}>
                                <div className="note-type-icon"><MdOutlineTextFields style={{ height: '100%', width: '100%' }}/></div>
                                <div className="note-type-header">Paragraphs</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}

                  <div id="errormsg">{errorMsg}</div>

                  <div id="button-actions">
                    <div className="button-action" onClick={() => {
                      if (addNote.show) {
                        setAddnote({ ...addNote, show: false, type: '', bullets: [] })
                      } else if (addChoicebox.show) {
                        setAddchoicebox({ ...addChoicebox, show: false })
                      }

                      setErrormsg('')
                    }}>Cancel</div>

                    {(addNote.show) && (
                      <div className="button-action" onClick={(e) => {
                        if (addNote.show) {
                          addTheNotes()
                        }

                        e.stopPropagation()
                      }}>{
                        postponeTaskbox.show ? "Update" : "Add"
                      }</div>
                    )}
                  </div>
                </div>
              )}
              {addCompany.show && (
                <div id="addcompany-box" onClick={(e) => e.stopPropagation()}>
                  <div>
                    <div id="add-headers">
                      {(!addChoicebox.show && datas.length > 0) && (
                        <div onClick={(e) => {
                          if (!addCompany.loading) {
                            e.stopPropagation()

                            setAddcompany({ ...addCompany, show: false })
                            setAddchoicebox({ ...addChoicebox, show: true })
                          }
                        }}><FaArrowLeft /></div>
                      )}

                      <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a company</div>
                    </div>

                    <div className="inputs">
                      <div className="input-container">
                        <div className="input-header">Company name:</div>
                        <input 
                          className="input-value" placeholder="Enter the company name" 
                          type="text" onChange={e => setAddcompany({ ...addCompany, name: e.target.value, errorMsg: '' })} 
                          value={addCompany.name} disabled={addCompany.loading} 
                          onKeyDown={e => {
                            e.stopPropagation()
            
                            if (e.keyCode === 13) {
                              addTheCompany()
                            }
                          }}
                        />
                      </div>

                      <div className="set-hour-options">
                        {!addCompany.setHours && (
                          <div className={"set-hour-option" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => setAddcompany({ ...addCompany, setHours: true })}>
                            <div className="column"><div className="set-hour-option-header">Set hours</div></div>
                            <div className="column"><div className="set-hour-option-icon"><FaRegClock style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                          </div>
                        )}
                        {addCompany.setHours && (
                          <div className={"set-hour-option" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => setAddcompany({ ...addCompany, setHours: false })}>
                            <div className="column"><div className="set-hour-option-icon"><IoMdClose style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                            <div className="column"><div className="set-hour-option-header">Don't set hours</div></div>
                          </div>
                        )}
                      </div>

                      {addCompany.setHours && (
                        <div id="working-hours" style={{ opacity: addCompany.loading ? 0.1 : 1 }}>
                          <div className="input-header" style={{ marginBottom: 20, marginTop: 10, textAlign: 'center' }}>Set company's business hours</div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Sun</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Sun"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Sun"].begin.hour
                                        :
                                        addCompany.hours["Sun"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Sun"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Sun"].begin.minute 
                                        : 
                                        addCompany.hours["Sun"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Sun"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Sun"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Sun"].end.hour
                                        :
                                        addCompany.hours["Sun"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Sun"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Sun"].end.minute 
                                        : 
                                        addCompany.hours["Sun"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Sun"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 0, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Mon</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Mon"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Mon"].begin.hour
                                        :
                                        addCompany.hours["Mon"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Mon"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Mon"].begin.minute 
                                        : 
                                        addCompany.hours["Mon"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Mon"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Mon"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Mon"].end.hour
                                        :
                                        addCompany.hours["Mon"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Mon"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Mon"].end.minute 
                                        : 
                                        addCompany.hours["Mon"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Mon"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 1, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Tue</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Tue"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Tue"].begin.hour
                                        :
                                        addCompany.hours["Tue"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Tue"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Tue"].begin.minute 
                                        : 
                                        addCompany.hours["Tue"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Tue"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Tue"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Tue"].end.hour
                                        :
                                        addCompany.hours["Tue"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Tue"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Tue"].end.minute 
                                        : 
                                        addCompany.hours["Tue"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Tue"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 2, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Wed</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Wed"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Wed"].begin.hour
                                        :
                                        addCompany.hours["Wed"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Wed"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Wed"].begin.minute 
                                        : 
                                        addCompany.hours["Wed"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Wed"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Wed"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Wed"].end.hour
                                        :
                                        addCompany.hours["Wed"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Wed"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Wed"].end.minute 
                                        : 
                                        addCompany.hours["Wed"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Wed"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 3, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Thu</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Thu"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Thu"].begin.hour
                                        :
                                        addCompany.hours["Thu"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Thu"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Thu"].begin.minute 
                                        : 
                                        addCompany.hours["Thu"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Thu"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Thu"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Thu"].end.hour
                                        :
                                        addCompany.hours["Thu"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Thu"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Thu"].end.minute 
                                        : 
                                        addCompany.hours["Thu"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Thu"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 4, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Fri</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Fri"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Fri"].begin.hour
                                        :
                                        addCompany.hours["Fri"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Fri"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Fri"].begin.minute 
                                        : 
                                        addCompany.hours["Fri"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Fri"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Fri"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Fri"].end.hour
                                        :
                                        addCompany.hours["Fri"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Fri"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Fri"].end.minute 
                                        : 
                                        addCompany.hours["Fri"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Fri"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 5, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="working-hours-time-container">
                              <div className="column"><div className="working-hours-time-header">Sat</div></div>

                              <div className="working-hours-time">
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Sat"].begin.hour < 10 ? 
                                        '0' + addCompany.hours["Sat"].begin.hour
                                        :
                                        addCompany.hours["Sat"].begin.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Sat"].begin.minute < 10 ? 
                                        '0' + addCompany.hours["Sat"].begin.minute 
                                        : 
                                        addCompany.hours["Sat"].begin.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Sat"].begin.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'begin', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="column"><div className="time-header">To</div></div>
                                <div className="time-input">
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'hour', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-hour">{
                                      addCompany.hours["Sat"].end.hour < 10 ? 
                                        '0' + addCompany.hours["Sat"].end.hour
                                        :
                                        addCompany.hours["Sat"].end.hour
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'hour', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="column"><div className="time-input-div">:</div></div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'minute', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-minute">{
                                      addCompany.hours["Sat"].end.minute < 10 ? 
                                        '0' + addCompany.hours["Sat"].end.minute 
                                        : 
                                        addCompany.hours["Sat"].end.minute
                                    }</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'minute', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="time-input-container">
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'period', 'up')}>
                                      <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="time-input-period">{addCompany.hours["Sat"].end.period}</div>
                                    <div className={"time-input-icon" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => adjustTime('company', 6, 'end', 'period', 'down')}>
                                      <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div>
                      <div id="errormsg">{addCompany.errorMsg}</div>

                      {addCompany.loading && (
                        <div id="loading-box" style={{ height: 20 }}>
                          <div id="loading-container">
                            <div id="loading-icon" style={{ height: 20, width: 20 }}>
                              <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div id="button-actions">
                      <div className={"button-action" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : () => {
                        setAddcompany({ ...addCompany, show: false })
                        setErrormsg('')
                      }}>Cancel</div>

                      {(addCompany.show || addTeam.show || addProject.show || addWorker.show || addTodolist.show || postponeTaskbox.show || addNote.show) && (
                        <div className={"button-action" + (addCompany.loading ? "-disabled" : "")} onClick={addCompany.loading ? null : (e) => {
                          addTheCompany()

                          e.stopPropagation()
                        }}>{
                          postponeTaskbox.show ? "Update" : "Add"
                        }</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {addTeam.show && (
                <div id="addteam-box" onClick={(e) => e.stopPropagation()}>
                  <div id="add-headers">
                    {(!addChoicebox.show && datas.length > 0) && (
                      <div onClick={(e) => {
                        if (!addTeam.loading) {
                          e.stopPropagation()

                          setAddteam({ ...addTeam, show: false })
                          setAddchoicebox({ ...addChoicebox, show: true })
                        }
                      }}><FaArrowLeft /></div>
                    )}

                    <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a team</div>
                  </div>

                  <div className="inputs">
                    <div className="input-container">
                      <div className="input-header">Team name:</div>
                      <input 
                        className="input-value" placeholder="Enter the team name" 
                        type="text" onChange={e => setAddteam({ ...addTeam, name: e.target.value, errorMsg: "" })} value={addTeam.name}
                        maxLength="50" disabled={addTeam.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()
          
                          if (e.keyCode === 13) {
                            addTheTeam()
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div id="errormsg">{addTeam.errorMsg}</div>

                    {addTeam.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}

                    <div id="button-actions">
                      <div className={"button-action" + (addTeam.loading ? "-disabled" : "")} onClick={addTeam.loading ? null : () => {
                        setAddteam({ ...addTeam, show: false })

                        setErrormsg('')
                      }}>Cancel</div>

                      <div className={"button-action" + (addTeam.loading ? "-disabled" : "")} onClick={addTeam.loading ? null : (e) => {
                        addTheTeam()

                        e.stopPropagation()
                      }}>{
                        postponeTaskbox.show ? "Update" : "Add"
                      }</div>
                    </div>
                  </div>
                </div>
              )}
              {addProject.show && (
                <div id="addproject-box" onClick={(e) => e.stopPropagation()}>
                  <div>
                    <div id="add-headers">
                      {(!addChoicebox.show && datas.length > 0) && (
                        <div onClick={(e) => {
                          if (!addProject.loading) {
                            e.stopPropagation()

                            cancelProject()
                            setAddchoicebox({ ...addChoicebox, show: true })
                          }
                        }}><FaArrowLeft /></div>
                      )}

                      <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a project</div>
                    </div>

                    <div className="inputs">
                      <div className="input-container">
                        <div className="input-header">Project name:</div>
                        <input 
                          className="input-value" placeholder="Enter the project name" 
                          type="text" 
                          onChange={e => setAddproject({ ...addProject, name: e.target.value, errorMsg: "" })} value={addProject.name}
                          maxLength="50" disabled={addProject.loading} 
                          onKeyDown={e => {
                            e.stopPropagation()
            
                            if (e.keyCode === 13) {
                              addTheProject()
                            }
                          }}
                        />
                      </div>

                      {addProject.timeType && (
                        <div className="row">
                          <div id="cancel-set-hour" onClick={addProject.loading ? null : () => setAddproject({ ...addProject, timeType: '' })}>
                            <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                            <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="set-hour-options">
                          {addProject.timeType != "startenddatetime" && (
                            <div className={"set-hour-option" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => createCalendar("", "project", { timeType: "startenddatetime" }, 0)}>
                              <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                              <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                            </div>
                          )}
                          {addProject.timeType != "duedatetime" && (
                            <div className={"set-hour-option" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => createCalendar("", "project", { timeType: "duedatetime" }, 0)}>
                              <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                              <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                            </div>
                          )}
                        </div>
                      </div>
                      
                      <div id="project-timecalendar">
                        {addProject.timeType && (
                          <>
                            {addProject.timeType === "startenddatetime" && (
                              <div className="datetime-selection">
                                <div className="datetime-selection-header">Select Start Date/Time</div>
                                <div className="date-selection">
                                  <div className="date-selection-headers">
                                    <div className="date-selection-arrow" onClick={() => createCalendar("prev", "project", "start", 0)}>
                                      <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                    <div className="date-selection-header">{addProject.start.calendar.header}</div>
                                    <div className="date-selection-arrow" onClick={() => createCalendar("next", "project", "start", 0)}>
                                      <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                    </div>
                                  </div>
                                  <div className="date-selection-dates">
                                    <div className="date-selection-date-header-row">
                                      <div className="date-selection-date-header-row-data">Sun</div>
                                      <div className="date-selection-date-header-row-data">Mon</div>
                                      <div className="date-selection-date-header-row-data">Tue</div>
                                      <div className="date-selection-date-header-row-data">Wed</div>
                                      <div className="date-selection-date-header-row-data">Thu</div>
                                      <div className="date-selection-date-header-row-data">Fri</div>
                                      <div className="date-selection-date-header-row-data">Sat</div>
                                    </div>

                                    {addProject.start.calendar.dates.map((info, infoIndex) => (
                                      <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                        {info.row.map((date, dateIndex) => (
                                          <div 
                                            key={"calendar-date-" + dateIndex} 
                                            className={"date-selection-date-row-data" + (addProject.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                            onClick={() => {
                                              if (date.date > 0 && date.unix > Date.now()) {
                                                const newAddproject = {...addProject}

                                                newAddproject.start.calendar.selectedDate = date.date
                                                newAddproject.errorMsg = ""

                                                setAddproject(newAddproject)
                                              }
                                            }}
                                          >
                                            {date.date > -1 ? date.date : ''}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="time-selections">
                                    <div className="time-selection">
                                      <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      <div className="selection-header">{addProject.start.time.hour}</div>
                                      <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    </div>
                                    <div className="column"><div id="time-selection-div">:</div></div>
                                    <div className="time-selection">
                                      <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      <div className="selection-header">{addProject.start.time.minute}</div>
                                      <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    </div>
                                    <div className="time-selection">
                                      <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      <div className="selection-header">{addProject.start.time.period}</div>
                                      <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="datetime-selection">
                              <div className="datetime-selection-header">Select End Date/Time</div>
                              <div className="date-selection">
                                <div className="date-selection-headers">
                                  <div className="date-selection-arrow" onClick={() => createCalendar("prev", "project", "end", 0)}>
                                    <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="date-selection-header">{addProject.end.calendar.header}</div>
                                  <div className="date-selection-arrow" onClick={() => createCalendar("next", "project", "end", 0)}>
                                    <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="date-selection-dates">
                                  <div className="date-selection-date-header-row">
                                    <div className="date-selection-date-header-row-data">Sun</div>
                                    <div className="date-selection-date-header-row-data">Mon</div>
                                    <div className="date-selection-date-header-row-data">Tue</div>
                                    <div className="date-selection-date-header-row-data">Wed</div>
                                    <div className="date-selection-date-header-row-data">Thu</div>
                                    <div className="date-selection-date-header-row-data">Fri</div>
                                    <div className="date-selection-date-header-row-data">Sat</div>
                                  </div>

                                  {addProject.end.calendar.dates.map((info, infoIndex) => (
                                    <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                      {info.row.map((date, dateIndex) => (
                                        <div 
                                          key={"calendar-date-" + dateIndex} 
                                          className={"date-selection-date-row-data" + (addProject.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                          onClick={() => {
                                            if (date.date > 0 && date.unix > Date.now()) {
                                              const newAddproject = {...addProject}

                                              newAddproject.end.calendar.selectedDate = date.date

                                              setAddproject(newAddproject)
                                            }
                                          }}
                                        >
                                          {date.date > -1 ? date.date : ''}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                                <div className="time-selections">
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addProject.end.time.hour}</div>
                                    <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="column"><div id="time-selection-div">:</div></div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addProject.end.time.minute}</div>
                                    <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addProject.end.time.period}</div>
                                    <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div id="errormsg">{addProject.errorMsg}</div>

                    {addProject.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}
                    <div id="button-actions">
                      <div className={"button-action" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => {
                        cancelProject()

                        setErrormsg('')
                      }}>Cancel</div>

                      <div className={"button-action" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : (e) => {
                        addTheProject()

                        e.stopPropagation()
                      }}>{
                        postponeTaskbox.show ? "Update" : "Add"
                      }</div>
                    </div>
                  </div>
                </div>
              )}
              {addWorker.show && (
                <div id="addworker-box" onClick={(e) => e.stopPropagation()}>
                  {addWorker.noMembers === false ? 
                    <>
                      <div id="add-headers">
                        {(!addChoicebox.show && datas.length > 0) && (
                          <div onClick={(e) => {
                            if (!addWorker.loading) {
                              e.stopPropagation()

                              cancelWorker()
                              setAddchoicebox({ ...addChoicebox, show: true })
                            }
                          }}><FaArrowLeft /></div>
                        )}

                        <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>
                          Add a user
                        </div>
                      </div>

                      <div id="add-worker" style={{ width: 300 }}>
                        {!addWorker.info ? 
                          <div id="add-worker-search">
                            <input 
                              maxLength="50" placeholder="Search worker name" 
                              onChange={(e) => searchTheWorkers(e.target.value)} 
                              disabled={addWorker.loading} 
                              onKeyDown={e => {
                                e.stopPropagation()
                
                                if (e.keyCode === 13) {
                                  addTheWorker()
                                }
                              }}
                            />
                          </div>
                          :
                          <div id="add-worker-selected">
                            <div id="add-worker-selected-header">{addWorker.info.name}</div>
                            <div id={"add-worker-selected-remove" + (addWorker.loading ? "-disabled" : "")} onClick={addWorker.loading ? null : () => setAddworker({ ...addWorker, info: null, showList: true, errorMsg: "" })} disabled={addWorker.loading}>
                              <IoMdClose style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        }

                        <div id="add-worker-workers-list">
                          {addWorker.showList && addWorker.list.map((worker => (
                            <div className="worker" key={"worker-" + worker.key} onClick={addWorker.loading ? null : () => setAddworker({ ...addWorker, info: {...worker}, showList: false, errorMsg: "" })}>
                              <div className="worker-name">{worker.name}</div>
                            </div>
                          )))}
                        </div>
                      </div>

                      <div id="errormsg">{addWorker.errorMsg}</div>

                      {addWorker.loading && (
                        <div id="loading-box" style={{ height: 20 }}>
                          <div id="loading-container">
                            <div id="loading-icon" style={{ height: 20, width: 20 }}>
                              <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                      )}

                      <div id="button-actions">
                        <div className={"button-action" + (addWorker.loading ? "-disabled" : "")} onClick={addWorker.loading ? null : () => cancelWorker()}>Cancel</div>
                        <div className={"button-action" + (addWorker.loading ? "-disabled" : "")} onClick={addWorker.loading ? null : (e) => {
                          addTheWorker()

                          e.stopPropagation()
                        }}>Add</div>
                      </div>
                    </>
                    :
                    <div id="no-members">
                      <div id="no-members-header">You have not added any members</div>

                      <div id="no-members-button" onClick={() => {
                        cancelWorker()
                        setAddnewmember({ 
                          ...addNewmember, 
                          show: true, 
                          firstName: '', 
                          lastName: '', 
                          email: '', 
                          projectId: -1, 
                          workingHours: {
                            "Sun": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Mon": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Tue": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Wed": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Thu": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Fri": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } },
                            "Sat": { "begin": { "hour": 9, "minute": 0, "period": "am" }, "end": { "hour": 5, "minute": 0, "period": "pm" } }
                          },
                          loading: false,
                          errorMsg: ""
                        })
                      }}>Add a user</div>
                    </div>
                  }
                </div>
              )}
              {addTodolist.show && (
                <div id="addnewtodolist-box" onClick={(e) => e.stopPropagation()}>
                  <div id="add-headers">
                    {(!addChoicebox.show && datas.length > 0) && (
                      <div id="add-back" onClick={addTodolist.loading ? null : (e) => {
                        if (!addTodolist.loading) {
                          e.stopPropagation()

                          setAddtodolist({ ...addTodolist, show: false })
                          setAddchoicebox({ ...addChoicebox, show: true })
                        }
                      }}><FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    )}

                    <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a new to-do list</div>
                  </div>

                  <div className="inputs">
                    <div className="input-container">
                      <div className="input-header">What's the name of this todo list:</div>
                      <input 
                        className="input-value" placeholder="Enter the name of this todo list" 
                        type="text" 
                        onChange={addTodolist.loading ? null : (e) => setAddtodolist({ ...addTodolist, name: e.target.value, errorMsg: "" })} 
                        value={addTodolist.name} maxLength="50" disabled={addTodolist.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()
          
                          if (e.keyCode === 13) {
                            addTheTodolist()
                          }
                        }}
                      />
                    </div>

                    <div id="todolist-list">
                      {addTodolist.list.map((listItem, index) => (
                        <div className="list-item-container">
                          <div className="list-item-header">Adding task #{index + 1}</div>
                          <div className="list-item" key={"todolist-" + index}>
                            <div className="column" style={{ width: '30%' }}>
                              <div className="list-item-headers">
                                <div className="list-item-delete" onClick={() => {
                                  const newList = [...addTodolist.list]

                                  newList.splice(index, 1)

                                  setAddtodolist({ ...addTodolist, list: newList })
                                }}>
                                  <IoIosClose style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>

                                <div className="list-item-input">
                                  <div className="list-item-input-header">Enter task name</div>
                                  <input  
                                    value={listItem.header}
                                    onChange={(e) => {
                                      const newList = [...addTodolist.list]

                                      newList[index].header = e.target.value

                                      setAddtodolist({ ...addTodolist, list: newList })
                                    }}
                                    onKeyUp={(e) => {
                                      if (e.keyCode === 13) {
                                        const newList = [...addTodolist.list]

                                        newList[index].editing = false

                                        setAddtodolist({ ...addTodolist, list: newList })
                                      }
                                    }}
                                    maxLength="50"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="column" style={{ width: '70%' }}>
                              <div className="todolist-list-calendar-option-container">
                                {listItem.timeType && (
                                  <div className="row" style={{ marginBottom: 20 }}>
                                    <div id="cancel-set-hour" onClick={addTodolist.loading ? null : () => {
                                      const newList = [...addTodolist.list]

                                      newList[index].timeType = ''

                                      setAddtodolist({ ...addTodolist, list: newList })
                                    }}>
                                      <div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div>
                                      <div id="cancel-set-hour-header">Don't Set DateTime</div>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="set-hour-options">
                                    {listItem.timeType != "startenddatetime" && (
                                      <div className={"set-hour-option" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                                        const newAddtodolist = {...addTodolist}
                                        const { timeType } = newAddtodolist.list[index]
    
                                        createCalendar("", "todolist", { timeType: "startenddatetime", index }, 0)
                                      }}>
                                        <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                                        <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                                      </div>
                                    )}
                                    {listItem.timeType != "duedatetime" && (
                                      <div className={"set-hour-option" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                                        const newAddtodolist = {...addTodolist}
                                        const { timeType } = newAddtodolist.list[index]
    
                                        createCalendar("", "todolist", { timeType: "duedatetime", index }, 0)
                                      }}>
                                        <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                                        <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {listItem.timeType && (
                                  <div className="todolist-list-timecalendar" onClick={(e) => e.stopPropagation()}>
                                    {listItem.timeType === "startenddatetime" && (
                                      <div className="datetime-selection">
                                        <div className="datetime-selection-header">Select Start DateTime</div>
                                        <div className="date-selection">
                                          <div className="date-selection-headers">
                                            <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("prev", "todolist", { index, type: "start" }, 0)}>
                                              <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                            </div>
                                            <div className="date-selection-header">{listItem.start.calendar.header}</div>
                                            <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("next", "todolist", { index, type: "start" }, 0)}>
                                              <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                            </div>
                                          </div>
                                          <div className="date-selection-dates">
                                            <div className="date-selection-date-header-row">
                                              <div className="date-selection-date-header-row-data">Sun</div>
                                              <div className="date-selection-date-header-row-data">Mon</div>
                                              <div className="date-selection-date-header-row-data">Tue</div>
                                              <div className="date-selection-date-header-row-data">Wed</div>
                                              <div className="date-selection-date-header-row-data">Thu</div>
                                              <div className="date-selection-date-header-row-data">Fri</div>
                                              <div className="date-selection-date-header-row-data">Sat</div>
                                            </div>

                                            {listItem.start.calendar.dates.map((info, infoIndex) => (
                                              <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                                {info.row.map((date, dateIndex) => (
                                                  <div 
                                                    key={"calendar-date-" + dateIndex} 
                                                    className={"date-selection-date-row-data" + (addTodolist.loading ? "-disabled" : listItem.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                                    onClick={addTodolist.loading ? null : () => {
                                                      if (date.date > 0 && date.unix > Date.now()) {
                                                        const newAddtodolist = {...addTodolist}

                                                        newAddtodolist.list[index].start.calendar.selectedDate = date.date
                                                        newAddtodolist.errorMsg = ""

                                                        setAddtodolist(newAddtodolist)
                                                      }
                                                    }}
                                                  >
                                                    {date.date > -1 ? date.date : ''}
                                                  </div>
                                                ))}
                                              </div>
                                            ))}
                                          </div>
                                          <div className="time-selections">
                                            <div className="time-selection">
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "up", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                              <div className="selection-header">{listItem.start.time.hour}</div>
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "down", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            </div>
                                            <div className="column"><div id="time-selection-div">:</div></div>
                                            <div className="time-selection">
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "up", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                              <div className="selection-header">{listItem.start.time.minute}</div>
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "down", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            </div>
                                            <div className="time-selection">
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                              <div className="selection-header">{listItem.start.time.period}</div>
                                              <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="datetime-selection">
                                      <div className="datetime-selection-header">Select End DateTime</div>
                                      <div className="date-selection">
                                        <div className="date-selection-headers">
                                          <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("prev", "todolist", { index, type: "end" }, 0)}>
                                            <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                          </div>
                                          <div className="date-selection-header">{listItem.end.calendar.header}</div>
                                          <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("next", "todolist", { index, type: "end" }, 0)}>
                                            <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                          </div>
                                        </div>
                                        <div className="date-selection-dates">
                                          <div className="date-selection-date-header-row">
                                            <div className="date-selection-date-header-row-data">Sun</div>
                                            <div className="date-selection-date-header-row-data">Mon</div>
                                            <div className="date-selection-date-header-row-data">Tue</div>
                                            <div className="date-selection-date-header-row-data">Wed</div>
                                            <div className="date-selection-date-header-row-data">Thu</div>
                                            <div className="date-selection-date-header-row-data">Fri</div>
                                            <div className="date-selection-date-header-row-data">Sat</div>
                                          </div>

                                          {listItem.end.calendar.dates.map((info, infoIndex) => (
                                            <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                              {info.row.map((date, dateIndex) => (
                                                <div 
                                                  key={"calendar-date-" + dateIndex} 
                                                  className={"date-selection-date-row-data" + (addTodolist.loading ? "-disabled" : listItem.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                                  onClick={addTodolist.loading ? null : () => {
                                                    if (date.date > 0 && date.unix > Date.now()) {
                                                      const newAddtodolist = {...addTodolist}

                                                      newAddtodolist.list[index].end.calendar.selectedDate = date.date
                                                      newAddtodolist.errorMsg = ""

                                                      setAddtodolist(newAddtodolist)
                                                    }
                                                  }}
                                                >
                                                  {date.date > -1 ? date.date : ''}
                                                </div>
                                              ))}
                                            </div>
                                          ))}
                                        </div>
                                        <div className="time-selections">
                                          <div className="time-selection">
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "up", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            <div className="selection-header">{listItem.end.time.hour}</div>
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "down", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          </div>
                                          <div className="column"><div id="time-selection-div">:</div></div>
                                          <div className="time-selection">
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "up", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            <div className="selection-header">{listItem.end.time.minute}</div>
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "down", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          </div>
                                          <div className="time-selection">
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                            <div className="selection-header">{listItem.end.time.period}</div>
                                            <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div id="todolist-actions">
                    <div className={"todolist-action" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                      const newList = [...addTodolist.list]
                      let empty = false

                      newList.forEach(function (info) {
                        if (!info.header) {
                          empty = true
                        }
                      })

                      if (empty) {
                        setErrormsg("There is an empty task")
                      } else {
                        newList.push({
                          key: "todolist-" + newList.length,
                          header: "",
                          timeType: '',
                          start: {
                            scheduled: null,
                            calendar: {
                              header: "",
                              dates: [
                                {
                                  key: "calendar-row-0",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-1",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-2",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-3",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-4",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                }
                              ],
                              selectedDate: 0,
                              monthNum: 0,
                              yearNum: 0
                            },
                            time: {
                              hour: 0,
                              minute: 0,
                              period: "am"
                            }
                          },
                          end: {
                            scheduled: null,
                            calendar: {
                              header: "",
                              dates: [
                                {
                                  key: "calendar-row-0",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-1",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-2",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-3",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                },
                                {
                                  key: "calendar-row-4",
                                  row: [{}, {}, {}, {}, {}, {}, {}]
                                }
                              ],
                              selectedDate: 0,
                              monthNum: 0,
                              yearNum: 0
                            },
                            time: {
                              hour: 0,
                              minute: 0,
                              period: "am"
                            }
                          }
                        })

                        setAddtodolist({ ...addTodolist, list: newList })
                      }
                    }}>
                      <div className="todolist-action-header">Add a task</div>
                      <div className="column">
                        <div className="todolist-action-icon">
                          <MdWorkOutline style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="errormsg">{addTodolist.errorMsg}</div>

                  {addTodolist.loading && (
                    <div id="loading-box" style={{ height: 20 }}>
                      <div id="loading-container">
                        <div id="loading-icon" style={{ height: 20, width: 20 }}>
                          <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  )}

                  <div id="button-actions">
                    <div className={"button-action" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => addTheTodolist()}>Add Todolist</div>
                  </div>
                </div>
              )}
              {addTask.show && (
                <div id="addnewtask-box" onClick={(e) => e.stopPropagation()}>
                  <div className="inputs">
                    <div className="input-container">
                      <div className="input-header">What's the name of this new task:</div>
                      <input 
                        className="input-value" placeholder="Enter the name of this new task" 
                        type="text" onChange={e => setAddtask({ ...addTask, name: e.target.value, errorMsg: "" })} value={addTask.name}
                        maxLength="50" disabled={addTask.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()
          
                          if (e.keyCode === 13) {
                            addTheTask()
                          }
                        }}
                      />
                    </div>

                    {addTask.timeType && (
                      <div className="row">
                        <div id="cancel-set-hour" onClick={addTask.loading ? null : () => setAddtask({ ...addTask, timeType: '' })}>
                          <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                          <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="set-hour-options" style={{ margin: addTask.timeType != '' ? '10px auto 20px auto' : '0 auto 50px auto'}}>
                        {addTask.timeType != "startenddatetime" && (
                          <div className={"set-hour-option" + (addTask.loading ? "-disabled" : "")} onClick={addTask.loading ? null : () => {                        
                            createCalendar("", "task", { ...addTask.data, timeType: "startenddatetime" }, 0)
                          }}>
                            <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                            <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                          </div>
                        )}
                        {addTask.timeType != "duedatetime" && (
                          <div className={"set-hour-option" + (addTask.loading ? "-disabled" : "")} onClick={addTask.loading ? null : () => {
                            createCalendar("", "task", { ...addTask.data, timeType: "duedatetime" }, 0)
                          }}>
                            <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                            <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                          </div>
                        )}
                      </div>
                    </div>

                    {addTask.timeType && (
                      <div id="addnewtask-timecalendar">
                        {addTask.timeType === "startenddatetime" && (
                          <div className="addnewtask-calendar">
                            <div className="datetime-selection">
                              <div className="datetime-selection-header">SELECT START DATE/TIME</div>
                              <div className="date-selection">
                                <div className="date-selection-headers">
                                  <div className="date-selection-arrow" onClick={addTask.loading ? null : () => createCalendar("prev", "task", "start", 0)}>
                                    <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div id="date-selection-header">{addTask.start.calendar.header}</div>
                                  <div className="date-selection-arrow" onClick={addTask.loading ? null : () => createCalendar("next", "task", "start", 0)}>
                                    <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="date-selection-dates">
                                  <div className="date-selection-date-header-row">
                                    <div className="date-selection-date-header-row-data">Sun</div>
                                    <div className="date-selection-date-header-row-data">Mon</div>
                                    <div className="date-selection-date-header-row-data">Tue</div>
                                    <div className="date-selection-date-header-row-data">Wed</div>
                                    <div className="date-selection-date-header-row-data">Thu</div>
                                    <div className="date-selection-date-header-row-data">Fri</div>
                                    <div className="date-selection-date-header-row-data">Sat</div>
                                  </div>

                                  {addTask.start.calendar.dates.map((info, index) => (
                                    <div className="date-selection-date-row" key={"newtask-" + index}>
                                      {info.row.map((date, index) => (
                                        <div 
                                          key={"calendar-date-" + index} 
                                          className={"date-selection-date-row-data" + (addTask.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                          onClick={addTask.loading ? null : () => {
                                            if (date.date > 0 && date.unix > Date.now()) {
                                              const newAddtask = {...addTask}

                                              newAddtask.start.calendar.selectedDate = date.date
                                              newAddtask.errorMsg = ""

                                              setAddtask(newAddtask)
                                            }
                                          }}
                                        >
                                          {date.date > -1 ? date.date : ''}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                                <div className="time-selections">
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("hour", "up", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTask.start.time.hour}</div>
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("hour", "down", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="column"><div id="time-selection-div">:</div></div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("minute", "up", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTask.start.time.minute}</div>
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("minute", "down", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("period", "", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTask.start.time.period}</div>
                                    <div className="selection-arrow" onClick={addTask.loading ? null : () => adjustDueTime("period", "", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        <div className="addnewtask-calendar">
                          <div className="datetime-selection">
                            <div className="datetime-selection-header">
                              SELECT END {addTask.timeType === "startenddatetime" ? "DATE/TIME" : "DUE DATE/TIME"}
                            </div>
                            <div className="date-selection">
                              <div className="date-selection-headers">
                                <div className="date-selection-arrow" onClick={() => createCalendar("prev", "task", "end", 0)}>
                                  <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                                <div id="date-selection-header">{addTask.end.calendar.header}</div>
                                <div className="date-selection-arrow" onClick={() => createCalendar("next", "task", "end", 0)}>
                                  <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                              <div className="date-selection-dates">
                                <div className="date-selection-date-header-row">
                                  <div className="date-selection-date-header-row-data">Sun</div>
                                  <div className="date-selection-date-header-row-data">Mon</div>
                                  <div className="date-selection-date-header-row-data">Tue</div>
                                  <div className="date-selection-date-header-row-data">Wed</div>
                                  <div className="date-selection-date-header-row-data">Thu</div>
                                  <div className="date-selection-date-header-row-data">Fri</div>
                                  <div className="date-selection-date-header-row-data">Sat</div>
                                </div>

                                {addTask.end.calendar.dates.map((info, index) => (
                                  <div className="date-selection-date-row" key={"newtask-" + index}>
                                    {info.row.map((date, index) => (
                                      <div 
                                        key={"calendar-date-" + index} 
                                        className={"date-selection-date-row-data" + (addTask.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                        onClick={() => {
                                          if (date.date > 0 && date.unix > Date.now()) {
                                            const newAddtask = {...addTask}

                                            newAddtask.end.calendar.selectedDate = date.date
                                            newAddtask.errorMsg = ""

                                            setAddtask(newAddtask)
                                          }
                                        }}
                                      >
                                        {date.date > -1 ? date.date : ''}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className="time-selections">
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTask.end.time.hour}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="column"><div id="time-selection-div">:</div></div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTask.end.time.minute}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTask.end.time.period}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div id="errormsg" style={{ fontSize: 15 }}>{addTask.errorMsg}</div>

                    {addTask.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}
                  
                    <div id="button-actions">
                      <div className={"button-action" + (addTask.loading ? "-disabled" : "")} onClick={addTask.loading ? null : (e) => {
                        cancelTask()

                        e.stopPropagation()
                      }}>Cancel</div>

                      <div className={"button-action" + (addTask.loading ? "-disabled" : "")} onClick={addTask.loading ? null : (e) => {
                        addTheTask()

                        e.stopPropagation()
                      }}>Add</div>
                    </div>
                  </div>
                </div>
              )}
              {postponeTaskbox.show && (
                <div id="postponetask-box" onClick={(e) => e.stopPropagation()}>
                  <div className="inputs">
                    <div className="input-container">
                      <div className="input-header">What's the task call:</div>
                      <input 
                        className="input-value" placeholder="Enter the worker's task" 
                        type="text" onChange={e => setPostponetaskbox({ ...postponeTaskbox, name: e.target.value, errorMsg: "" })} value={postponeTaskbox.name}
                        maxLength="50" disabled={postponeTaskbox.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()
          
                          if (e.keyCode === 13) {
                            postponeTheTask()
                          }
                        }}
                      />
                    </div>

                    {postponeTaskbox.timeType && (
                      <div className="row">
                        <div id="cancel-set-hour" onClick={postponeTaskbox.loading ? null : () => setPostponetaskbox({ ...postponeTaskbox, timeType: '' })}>
                          <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                          <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="set-hour-options" style={{ margin: postponeTaskbox.timeType != '' ? '10px auto 20px auto' : '0 auto 50px auto'}}>
                        {postponeTaskbox.timeType != "startenddatetime" && (
                          <div className={"set-hour-option" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => {   
                            const { id, name } = {...postponeTaskbox}
                            const data = { id, name }

                            createCalendar("", "postpone", { ...data, timeType: "startenddatetime" }, 0)
                          }}>
                            <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                            <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                          </div>
                        )}
                        {postponeTaskbox.timeType != "duedatetime" && (
                          <div className={"set-hour-option" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => {
                            const { id, name } = {...postponeTaskbox}
                            const data = { id, name }

                            createCalendar("", "postpone", { ...data, timeType: "duedatetime" }, 0)
                          }}>
                            <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                            <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                          </div>
                        )}
                      </div>
                    </div>

                    {postponeTaskbox.timeType && (
                      <div id="postponetask-timecalendar">
                        {postponeTaskbox.timeType === "startenddatetime" && (
                          <div className="postponetask-calendar">
                            <div className="datetime-selection">
                              <div className="datetime-selection-header">CHOOSE START DATE/TIME</div>
                              <div className="date-selection">
                                <div className="date-selection-headers">
                                  <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("prev", "postpone", "start", 0)}>
                                    <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div id="date-selection-header">{postponeTaskbox.start.calendar.header}</div>
                                  <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("next", "postpone", "start", 0)}>
                                    <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="date-selection-dates">
                                  <div className="date-selection-date-header-row">
                                    <div className="date-selection-date-header-row-data">Sun</div>
                                    <div className="date-selection-date-header-row-data">Mon</div>
                                    <div className="date-selection-date-header-row-data">Tue</div>
                                    <div className="date-selection-date-header-row-data">Wed</div>
                                    <div className="date-selection-date-header-row-data">Thu</div>
                                    <div className="date-selection-date-header-row-data">Fri</div>
                                    <div className="date-selection-date-header-row-data">Sat</div>
                                  </div>

                                  {postponeTaskbox.start.calendar.dates.map((info, index) => (
                                    <div className="date-selection-date-row" key={"postponetask-" + index}>
                                      {info.row.map((date, index) => (
                                        <div 
                                          key={"calendar-date-" + index} 
                                          className={"date-selection-date-row-data" + (postponeTaskbox.loading ? "-disabled" : postponeTaskbox.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                          onClick={postponeTaskbox.loading ? null : () => {
                                            if (date.date > 0 && date.unix > Date.now()) {
                                              const newPostponetaskbox = {...postponeTaskbox}

                                              newPostponetaskbox.start.calendar.selectedDate = date.date
                                              newPostponetaskbox.errorMsg = ""

                                              setPostponetaskbox(newPostponetaskbox)
                                            }
                                          }}
                                        >
                                          {date.date > -1 ? date.date : ''}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                                <div className="time-selections">
                                  <div className="time-selection">
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "up", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{postponeTaskbox.start.time.hour}</div>
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "down", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="column"><div id="time-selection-div">:</div></div>
                                  <div className="time-selection">
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "up", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{postponeTaskbox.start.time.minute}</div>
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "down", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="time-selection">
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{postponeTaskbox.start.time.period}</div>
                                    <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="postponetask-calendar">
                          <div className="datetime-selection">
                            <div className="datetime-selection-header">CHOOSE END {postponeTaskbox.timeType === "startenddatetime" ? "DATE/TIME" : "DUE DATE/TIME"}</div>
                            <div className="date-selection">
                              <div className="date-selection-headers">
                                <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("prev", "postpone", "end", 0)}>
                                  <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                                <div id="date-selection-header">{postponeTaskbox.end.calendar.header}</div>
                                <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("next", "postpone", "end", 0)}>
                                  <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                              <div className="date-selection-dates">
                                <div className="date-selection-date-header-row">
                                  <div className="date-selection-date-header-row-data">Sun</div>
                                  <div className="date-selection-date-header-row-data">Mon</div>
                                  <div className="date-selection-date-header-row-data">Tue</div>
                                  <div className="date-selection-date-header-row-data">Wed</div>
                                  <div className="date-selection-date-header-row-data">Thu</div>
                                  <div className="date-selection-date-header-row-data">Fri</div>
                                  <div className="date-selection-date-header-row-data">Sat</div>
                                </div>

                                {postponeTaskbox.end.calendar.dates.map((info, index) => (
                                  <div className="date-selection-date-row" key={"postponetask-" + index}>
                                    {info.row.map((date, index) => (
                                      <div 
                                        key={"calendar-date-" + index} 
                                        className={"date-selection-date-row-data" + (postponeTaskbox.loading ? "-disabled" : postponeTaskbox.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                        onClick={postponeTaskbox.loading ? null : () => {
                                          if (date.date > 0 && date.unix > Date.now()) {
                                            const newPostponetaskbox = {...postponeTaskbox}

                                            newPostponetaskbox.end.calendar.selectedDate = date.date
                                            newPostponetaskbox.errorMsg = ""

                                            setPostponetaskbox(newPostponetaskbox)
                                          }
                                        }}
                                      >
                                        {date.date > -1 ? date.date : ''}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className="time-selections">
                                <div className="time-selection">
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "up", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{postponeTaskbox.end.time.hour}</div>
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "down", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="column"><div id="time-selection-div">:</div></div>
                                <div className="time-selection">
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "up", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{postponeTaskbox.end.time.minute}</div>
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "down", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="time-selection">
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{postponeTaskbox.end.time.period}</div>
                                  <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <div id="errormsg" style={{ fontSize: 15 }}>{postponeTaskbox.errorMsg}</div>

                    {postponeTaskbox.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}

                    <div id="button-actions">
                      <div className={"button-action" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : (e) => {
                        cancelPostponeTask()

                        e.stopPropagation()
                      }}>Cancel</div>

                      <div className={"button-action" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : (e) => {
                        postponeTheTask()

                        e.stopPropagation()
                      }}>{postponeTaskbox.show ? "Update" : "Add"}</div>
                    </div>
                  </div>
                </div>
              )}
              {addNewmember.show && (
                <div id="new-member-box" onClick={e => e.stopPropagation()}>
                  <div id="add-headers">
                    <div id="add-header" style={{ width: '100%' }}>Add a new employee</div>
                  </div>

                  <div id="inputs">
                    <div className="input-container">
                      <div className="input-header">Enter their first name:</div>
                      <div className="input-value">
                        <input 
                          maxLength="50" type="text" 
                          onChange={e => setAddnewmember({ ...addNewmember, firstName: e.target.value })} 
                          value={addNewmember.firstName} disabled={addNewmember.loading} 
                          onKeyDown={e => {
                            e.stopPropagation()

                            if (e.keyCode === 13) {
                              addTheNewMember()
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-container">
                      <div className="input-header">Enter their last name:</div>
                      <div className="input-value">
                        <input 
                          maxLength="50" type="text" 
                          onChange={e => setAddnewmember({ ...addNewmember, lastName: e.target.value })} 
                          value={addNewmember.lastName} disabled={addNewmember.loading} 
                          onKeyDown={e => {
                            e.stopPropagation()

                            if (e.keyCode === 13) {
                              addTheNewMember()
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-container">
                      <div className="input-header">Enter their e-mail:</div>
                      <div className="input-value">
                        <input 
                          maxLength="50" type="text" 
                          onChange={e => setAddnewmember({ ...addNewmember, email: e.target.value })} 
                          value={addNewmember.email} disabled={addNewmember.loading} 
                          onKeyDown={e => {
                            e.stopPropagation()

                            if (e.keyCode === 13) {
                              addTheNewMember()
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="set-hour-options">
                      {!addNewmember.setHours && (
                        <div className={"set-hour-option" + (addNewmember.loading ? "-disabled" : addNewmember.setHours ? "-selected" : "")} onClick={addNewmember.loading ? null : () => setAddnewmember({ ...addNewmember, setHours: true })}>
                          <div className="column"><div className="set-hour-option-header">Set hours</div></div>
                          <div className="column"><div className="set-hour-option-icon"><FaRegClock style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                        </div>
                      )}
                      {addNewmember.setHours && (
                        <div className={"set-hour-option" + (addNewmember.loading ? "-disabled" : addNewmember.setHours ? "" : "-selected")} onClick={addNewmember.loading ? null : () => setAddnewmember({ ...addNewmember, setHours: false })}>
                          <div className="column"><div className="set-hour-option-header">Don't set hours</div></div>
                          <div className="column"><div className="set-hour-option-icon"><IoMdClose style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                        </div>
                      )}
                    </div>
                  </div>

                  {addNewmember.setHours && (
                    <div id="working-hours">
                      <div id="working-hours-header">Set their hours</div>

                      <div id="working-hours-times">
                        <div className="working-hours-times-row">
                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Sun</div></div>

                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Sun"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sun"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Sun"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Sun"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sun"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Sun"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Sun"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Sun"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sun"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Sun"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Sun"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sun"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Sun"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Sun"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 0, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Mon</div></div>
                          
                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Mon"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Mon"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Mon"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Mon"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Mon"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Mon"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Mon"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Mon"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Mon"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Mon"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Mon"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Mon"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Mon"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Mon"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 1, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="working-hours-times-row">
                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Tue</div></div>
                          
                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Tue"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Tue"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Tue"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Tue"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Tue"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Tue"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Tue"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Tue"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Tue"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Tue"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Tue"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Tue"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Tue"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Tue"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 2, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Wed</div></div>

                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Wed"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Wed"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Wed"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Wed"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Wed"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Wed"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Wed"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Wed"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Wed"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Wed"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Wed"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Wed"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Wed"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Wed"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 3, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="working-hours-times-row">
                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Thu</div></div>
                          
                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Thu"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Thu"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Thu"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Thu"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Thu"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Thu"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Thu"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Thu"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Thu"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Thu"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Thu"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Thu"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Thu"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Thu"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 4, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Fri</div></div>
                          
                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Fri"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Fri"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Fri"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Fri"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Fri"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Fri"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Fri"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Fri"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Fri"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Fri"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Fri"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Fri"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Fri"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Fri"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 5, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="working-hours-times-row">
                          <div className="working-hours-time-container">
                            <div className="column"><div className="working-hours-time-header">Sat</div></div>
                          
                            <div className="working-hours-time">
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Sat"]["begin"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sat"]["begin"]["hour"]
                                      :
                                      addNewmember.workingHours["Sat"]["begin"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Sat"]["begin"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sat"]["begin"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Sat"]["begin"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Sat"]["begin"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'begin', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                              <div className="column"><div className="time-header">To</div></div>
                              <div className="time-input">
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'hour', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-hour">{
                                    addNewmember.workingHours["Sat"]["end"]["hour"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sat"]["end"]["hour"]
                                      :
                                      addNewmember.workingHours["Sat"]["end"]["hour"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'hour', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="column"><div className="time-input-div">:</div></div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'minute', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-minute">{
                                    addNewmember.workingHours["Sat"]["end"]["minute"] < 10 ? 
                                      '0' + addNewmember.workingHours["Sat"]["end"]["minute"] 
                                      : 
                                      addNewmember.workingHours["Sat"]["end"]["minute"]
                                  }</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'minute', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="time-input-container">
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'period', 'up')}>
                                    <IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div className="time-input-period">{addNewmember.workingHours["Sat"]["end"]["period"]}</div>
                                  <div className="time-input-icon" onClick={() => adjustTime('member', 6, 'end', 'period', 'down')}>
                                    <IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <div id="errormsg">{addNewmember.errorMsg}</div>

                    {addNewmember.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div id="button-actions">
                        <div className={"button-action" + (addNewmember.loading ? "-disabled" : "")} onClick={addNewmember.loading ? null : () => {
                          setErrormsg("")
                          setAddnewmember({ ...addNewmember, show: false })
                        }}>Cancel</div>
                        <div className={"button-action" + (addNewmember.loading ? "-disabled" : "")} onClick={addNewmember.loading ? null : (e) => addTheNewMember()}>Add</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(deleteChoicebox.show) && (
                <div id="delete-box" onClick={e => e.stopPropagation()}>
                  <div id="delete-headers">
                    <div id="delete-header">{deleteChoicebox.show && 'Delete'}</div>
                  </div>

                  {deleteChoicebox.show && <div style={{ fontSize: 30, textAlign: 'center' }}><strong>{deleteChoicebox.type}</strong>: {deleteChoicebox.name}</div>}

                  {deleteChoicebox.loading && (
                    <div id="loading-box" style={{ height: 20 }}>
                      <div id="loading-container">
                        <div id="loading-icon" style={{ height: 20, width: 20 }}>
                          <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  )}

                  <div id="button-actions">
                    <div className={"button-action" + (deleteChoicebox.loading ? "-disabled" : "")} onClick={deleteChoicebox.loading ? null : () => setDeletechoicebox({ ...deleteChoicebox, show: false })}>Cancel</div>
                    <div className={"button-action" + (deleteChoicebox.loading ? "-disabled" : "")} onClick={deleteChoicebox.loading ? null : () => deleteTheChoice()}>Yes</div>
                  </div>
                </div>
              )}
              {(seeNotesbox.show) && (
                <div id="see-notes-box" onClick={e => e.stopPropagation()}>
                  <div id="see-notes-header">
                    Notes for <strong>{seeNotesbox.header}</strong>
                  </div>

                  {seeNotesbox.type === "Bullet" ? 
                    <div id="see-notes-bullets-list">
                      {seeNotesbox.content.map((bullet, index) => (
                        <div className="see-notes-bullet-item" key={"see-notes-bullet-" + index}>
                          <div className="column"><div className="see-notes-item-dot"/></div>
                          <div className="see-notes-item-header">{bullet}</div>
                        </div>
                      ))}
                    </div>
                    :
                    <div id="see-notes-paragraphs">{seeNotesbox.content}</div>
                  }

                  <div id="button-actions">
                    <div className="button-action" onClick={() => setSeenotesbox({ ...seeNotesbox, show: false })}>Close</div>
                  </div>
                </div>
              )}
              {(seeTaskinfobox.show) && (
                <div id="see-task-info-box" onClick={(e) => e.stopPropagation()}>
                  <div id="see-task-info-header">
                    {seeTaskinfobox.parents.join(" / ")}<br/>
                    <strong>{seeTaskinfobox.info.name}</strong>
                  </div>

                  <div id="see-task-info-comments-header">Comment(s)</div>

                  <div id="see-task-info-comments">
                    {seeTaskinfobox.comments.map((comment, index) => (
                      <div className="see-task-info-comment" key={comment.key}>
                        <div className="see-task-info-comment-profile">
                          <div>{comment.name.substr(0, 1).toUpperCase()}</div>
                        </div>
                        <div className="see-task-info-comment-info">
                          <div className="info-name">{comment.name}</div>

                          {comment.editing ? 
                            <textarea className="info-info-edit" onChange={e => {
                              const newSeetaskinfobox = {...seeTaskinfobox}

                              newSeetaskinfobox.comments[index].info = e.target.value

                              setSeetaskinfobox(newSeetaskinfobox)
                            }} value={comment.info} disabled={seeTaskinfobox.loading}/>
                            :
                            <div className="info-info">{comment.info}</div>
                          }
                        </div>
                        {comment.setAction && (
                          <div className="see-task-info-comment-actions">
                            <div className="see-task-info-comment-action" onClick={seeTaskinfobox.loading ? null : () => deleteTheComment(comment.id, index)}><IoIosClose style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                            <div className="see-task-info-comment-action" onClick={seeTaskinfobox.loading ? null : () => {
                              const newSeetaskinfobox = {...seeTaskinfobox}
                              const comment = newSeetaskinfobox.comments[index]

                              if (comment.editing) { // save comment
                                saveTheComment(comment.id, index)
                              } else { // open comment edit
                                comment.editing = true

                                newSeetaskinfobox.comments[index] = comment
                                setSeetaskinfobox(newSeetaskinfobox)
                              }
                            }}>
                              {comment.editing ? 
                                <FaCheck style={{ color: 'black', height: '100%', width: '100%' }}/>
                                :
                                <MdEdit style={{ color: 'black', height: '100%', width: '100%' }}/>
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div id="see-task-info-comment-input">
                    <div id="comment-input-header">Enter a comment</div>
                    <div id="comment-input-holder">
                      <textarea 
                        onChange={e => setSeetaskinfobox({ ...seeTaskinfobox, comment: e.target.value })}
                        value={seeTaskinfobox.comment} disabled={seeTaskinfobox.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()

                          if (e.keyCode === 13) {
                            postTheComment()
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div id="button-actions">
                    <div className={"button-action" + (seeTaskinfobox.loading ? "-disabled" : "")} onClick={seeTaskinfobox.loading ? null : () => postTheComment()}>Post</div>
                  </div>
                </div>
              )}
              {(usageRecords.show) && <Usagerecords usageRecords={usageRecords}/>}
              {requestFeaturebox.show && (
                <div id="request-feature-box" onClick={(e) => e.stopPropagation()}>
                  <div id="request-feature-header">What feature do you want ?</div>

                  <div id="request-feature-input">
                    <textarea 
                      type="text" maxLength="100" 
                      onChange={(e) => setRequestfeaturebox({ ...requestFeaturebox, input: e.target.value })}
                    >{requestFeaturebox.input}</textarea>
                  </div>

                  <div id="request-feature-non">
                    <div id="request-feature-non-header">Anonymously ?</div>
                    <div className="row">
                      <div id="request-feature-non-actions">
                        <div className={"request-feature-non" + (requestFeaturebox.isPrivate ? "-selected" : "")} onClick={() => {
                          if (!requestFeaturebox.isPrivate) {
                            setRequestfeaturebox({ ...requestFeaturebox, isPrivate: true })
                          }
                        }}>Yes</div>
                        <div className={"request-feature-non" + (requestFeaturebox.isPrivate ? "" : "-selected")} onClick={() => {
                          if (requestFeaturebox.isPrivate) {
                            setRequestfeaturebox({ ...requestFeaturebox, isPrivate: false })
                          }
                        }}>No</div>
                      </div>
                    </div>
                  </div>

                  <div id="errormsg">{requestFeaturebox.errorMsg}</div>

                  <div className="row">
                    <div id="button-actions">
                      <div className={"button-action" + (requestFeaturebox.loading ? "-disabled" : "")} onClick={requestFeaturebox.loading ? null : () => {
                        setErrormsg("")
                        setRequestfeaturebox({ ...requestFeaturebox, show: false, input: '', isPrivate: true, loading: false, errorMsg: '' })
                      }}>Cancel</div>
                      <div className={"button-action" + (requestFeaturebox.loading ? "-disabled" : "")} onClick={requestFeaturebox.loading ? null : (e) => requestTheFeature()}>Send</div>
                    </div>
                  </div>
                </div>
              )}
              {showLicenses && <Licenses/>}
            </div>
          )}

          {(showExceedusagebox.show || moveLoading) && (
            <div id="popup-box" onClick={() => {
              setShowexceedusagebox({ ...showExceedusagebox, show: false })
            }}>
              {(showExceedusagebox.show) && (
                <div id="exceed-usage-box" onClick={(e) => e.stopPropagation()}>
                  <div id="exceed-usage-header">You have reached your usage limit</div>
                </div>
              )}
              {moveLoading && (
                <div id="move-loading">
                  <div id="loading-container" style={{ margin: '10px 0' }}>
                    <div id="loading-icon" style={{ height: 30, width: 30 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );

  return <div/>
}
