const url = process.env.REACT_APP_URL

export const addNotes = data => {
  return fetch(url + '/api/note/add_note', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const seeNotes = data => {
  return fetch(url + '/api/note/see', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
