import "./App.scss";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";

// pages
// web
import Landing from "./pages/landing"
import Login from "./pages/login";
import Register from "./pages/register";
import Terms from "./pages/terms";
import Policy from "./pages/policy"
import Mission from "./pages/mission";
import Dashboard from "./pages/dashboard";
import Pricing from "./pages/pricing";
import Subscribed from "./pages/subscribed";
import Seereports from "./pages/seereports";
import Mobilecoming from "./pages/mobilecoming"

export default function App() {
  const [userId, setUserid] = useState(null)
  const [route, setRoute] = useState('')

  useEffect(() => {
    const initialize = async() => {
      const token = localStorage.getItem("token")

      setRoute(token ? 'dashboard' : 'landing')
    }

    initialize()
  }, [])

  if (route)
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={
            isMobile ? 
              <Landing/>
              :
              route == 'dashboard' ? 
                <Dashboard/> 
                : 
                <Landing/>
          }/>
          <Route path="/landing" element={<Landing/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/policy" element={<Policy/>}/>
          <Route path="/ourmission" element={<Mission/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/pricing" element={<Pricing/>}/>
          <Route path="/subscribed" element={<Subscribed/>}/>
          <Route path="/seereports" element={<Seereports/>}/>
          <Route path="/mobile" element={<Mobilecoming/>}/>
        </Routes>
      </BrowserRouter>
    );

  return null
}
