const url = process.env.REACT_APP_URL

export const postComment = data => {
  return fetch(url + '/api/comment/post_comment', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const deleteComment = data => {
  return fetch(url + '/api/comment/delete_comment', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const saveComment = data => {
  return fetch(url + '/api/comment/save_comment', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
