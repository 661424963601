import "./subscribed.scss";
import { useEffect, useState } from "react";
import { updatePlan } from "../../apis/user"

export default function Planupgraded() {
  const urlParams = new URLSearchParams(window.location.search);
  const checkoutSessionId = urlParams.get('checkout_session_id');

  const updateThePlan = () => {
    const data = { csId: checkoutSessionId }

    updatePlan(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {

        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }

  useEffect(() => {
    if (checkoutSessionId) {
      updateThePlan()
    }
  }, [])

  return (
    <div id="subscribed">
      <div id="subscribed-web">
        <div id="logo">
          <img src="/logo.png"/>
        </div>
        <div className="header">You are subscribed. Thank you for trying out Detach</div>
        <div className="header">You can close this window and return to the desktop application and click 'Done'</div>
      </div>
    </div>
  )
}