import "./mission.scss";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

// components
import Mainheader from "../../components/mainheader"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

const header = "Why Us"
const mission = "Our mission"

export default function About() {
  const [showLicense, setShowlicense] = useState(false)

  return (
    <div id="mission-page">
      <BrowserView>        
        <div id="mission-web">
          <Header/>

          <div className="header">{header}</div>

          <div className="infos">
            <div className="info">
              Welcome to <strong>Detach</strong>. We know what you must be thinking. "Oh gosh, another must-be frustrating project management tool".
              NOPE. Hell-no!. We are a group of project managers and software developers who have been using many project management software for years
              at our work so we know and understand the good and bad of these tools.
              <br/><br/><br/><br/>
              What separates <strong>Detach</strong> from other management tools is the idea of the term "Together". We want to build Detach to enable you
              to see everything together on one screen instead of clicking around and getting lost with headaches and fustrations.
              <br/><br/><br/><br/>
              We are extremely passioniate about simplicity and flexible and we want to shape <strong>Detach</strong> to make it fit that way, so that's the
              main purpose we're moving towards
              <br/><br/><br/><br/>
              We have been using different tools and learning for years but what's more important to us is the opinions and feedbacks we get from users.
              We believe our users are the complete solution to our goal as we move forward. So let's work together to build this solution into a world wide management tool
            </div>
          </div>

          <div className="header">{mission}</div>

          <div className="infos" style={{ marginBottom: 150 }}>
            <div className="info">
              Detach to allow users to create, schedule and manage their tasks, todolists, projects, teams, companies, and employees and easily manage everything all on one screen.
              <br/><br/>
              Unlike other project management tools, Detach enables you to manage your work with only a few clicks.
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="mission-mobile">
          <Header/>

          <div className="header">{header}</div>

          <div className="infos">
            <div className="info">
              Welcome to <strong>Detach</strong>. We know what you must be thinking. "Oh gosh, another must-be frustrating project management tool".
              NOPE. Hell-no!. We are a group of project managers and software developers who have been using many project management software for years
              at our work so we know and understand the good and bad of these tools.
              <br/><br/><br/><br/>
              What separates <strong>Detach</strong> from other management tools is the idea of the term "Together". We want to build Detach to enable you
              to see everything together on one screen instead of clicking around and getting lost with headaches and fustrations.
              <br/><br/><br/><br/>
              We are extremely passioniate about simplicity and flexible and we want to shape <strong>Detach</strong> to make it fit that way, so that's the
              main purpose we're moving towards
              <br/><br/><br/><br/>
              We have been using different tools and learning for years but what's more important to us is the opinions and feedbacks we get from users.
              We believe our users are the complete solution to our goal as we move forward. So let's work together to build this solution into a world wide management tool
            </div>
          </div>

          <div className="header">{mission}</div>

          <div className="infos">
            <div className="info">
              Detach to allow users to create, schedule and manage their tasks, todolists, projects, teams, companies, and employees and easily manage everything all on one screen.
              <br/><br/>
              Unlike other project management tools, Detach enables you to manage your work with only a few clicks.
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </MobileView>
    </div>
  )
}
