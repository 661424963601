import "./header.scss";
import { BrowserView, MobileView } from "react-device-detect"

export default function Header() {
  return (
    <>
      <BrowserView>
        <div id="header-web-comp">
          <div id="header-row">
            <div id="icon-holder" onClick={() => window.location = "/"}>
              <div className="column">
                <div id="icon">
                  <img alt="icon" src="/logo.png"/>
                </div>
              </div>
              <div id="icon-headers">
                <div className="column">
                  <div id="icon-header-title">
                    DETACH
                  </div>
                  <div id="icon-header-minititle">
                    <div style={{ fontSize: 13 }}><strong style={{ fontWeight: 100 }}>ALL-ON-ONE <strong>SCREEN</strong> MANAGEMENT PLATFORM</strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div id="auths">
                {localStorage.getItem("token") ? 
                  <>
                    <div className="auth" onClick={() => {
                      localStorage.clear()

                      window.location = "/"
                    }}>LOG-OUT</div>
                  </>
                  :
                  <>
                    {/* <div className="auth" onClick={() => window.location = "/login"}>LOGIN</div>
                    <div className="auth" onClick={() => window.location = "/register"}>REGISTER</div> */}
                  </>
                }

                <div className="auth" onClick={() => window.location = "/"}>HOME</div>
                <div className="auth" onClick={() => window.location = "/pricing"}>PRICING</div>
                <div className="auth" onClick={() => window.location = "/ourmission"}>MISSION</div>
                <div className="auth" onClick={() => window.location = "/terms"}>TERMS</div>
                <div className="auth" onClick={() => window.location = "/policy"}>POLICY</div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="header-mobile-comp">
          <div id="icon-holder" onClick={() => window.location = "/"}>
            <div id="icon">
              <img alt="icon" src="/logo.png"/>
            </div>
            <div id="icon-header-title">
              DETACH
            </div>
            <div id="icon-header-minititle">
              <div><strong style={{ fontWeight: 100 }}>ALL-ON-ONE <strong>SCREEN</strong><br/>MANAGEMENT PLATFORM</strong></div>
            </div>
          </div>
          <div className="column">
            <div className="auths">
              <div className="auth" onClick={() => window.location = "/"}>HOME</div>
              
              {localStorage.getItem("token") ? 
                <>
                  
                </>
                :
                <>
                  <div className="auth" onClick={() => window.location = "/login"}>LOGIN</div>
                  <div className="auth" onClick={() => window.location = "/register"}>REGISTER</div>
                </>
              }

              <div className="auth" onClick={() => window.location = "/pricing"}>PRICING</div>
            </div>
            <div className="auths">

              <div className="auth" onClick={() => window.location = "/ourmission"}>MISSION</div>
              <div className="auth" onClick={() => window.location = "/terms"}>TERMS</div>
              <div className="auth" onClick={() => window.location = "/policy"}>POLICY</div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}
