import "./terms.scss";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import Header from "../../components/header";
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Terms() {
  const [showLicense, setShowlicense] = useState(false)

  return (
    <>
      <BrowserView>
        <div id="terms-web-page">
          <Header/>

          <div id="main">
            <div id="terms-header">Terms of Service</div>

            <div id="terms-lastupdated">
              Last Updated: September 30, 2024<br/><br/>

              Welcome to [Your App Name]! These Terms of Service ("Terms") govern your use of our web and desktop applications ("Services"). 
              By accessing or using our Services, you agree to these Terms. If you do not agree, please do not use our Services.
            </div>

            <div className="terms-header">1. Acceptance of Terms</div>
            <div className="terms-info">
              By using our Services, you confirm that you are at least 13 years old and have 
              the legal capacity to enter into these Terms.
            </div>

            <div className="terms-header">2. User Accounts</div>
            <div className="terms-info">
              <strong>Account Creation:</strong> To use certain features, you may need to create an account. 
              You agree to provide accurate, complete, and updated information.
              <br/><br/>
              <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of 
              your account credentials and for all activities that occur under your account.
            </div>

            <div className="terms-header">3. Free Trial</div>
            <div className="terms-info">
              <strong>Free Trial Period:</strong> New users will receive a one-week free trial of our Services, beginning on the day you create your account.
              <br/><br/>
              <strong>Subscription Activation:</strong> At the end of the trial period, your subscription will automatically 
              convert to a paid subscription at a rate of $9.99 per month, unless you cancel before the trial 
              ends.
            </div>

            <div className="terms-header">4. Subscription and Payment</div>
            <div className="terms-info">
              <strong>Fees:</strong> The subscription fee is $9.99 per month, billed monthly after the free trial ends.
              <br/><br/>
              <strong>Billing:</strong> We will charge your chosen payment method. You are responsible for maintaining updated payment information.
            </div>

            <div className="terms-header">5. User Responsibilities</div>
            <div className="terms-info">
              You agree not to use the Services to:

              <ul>
                <li>Violate any applicable laws or regulations.</li>
                <li>Impersonate any person or entity.</li>
                <li>Transmit any harmful or malicious code.</li>
                <li>Engage in any conduct that restricts or inhibits anyone's use of the Services.</li>
              </ul>
            </div>

            <div className="terms-header">5.1 Disclaimer of Responsibility</div>
            <div className="terms-info">
              We are not responsible for any misplaced, incorrect, or incomplete information provided by users. 
              It is your responsibility to ensure that all information entered into the Services is accurate and 
              up-to-date. We disclaim any liability for errors or omissions in user-generated content.
            </div>

            <div className="terms-header">6. Content Ownership</div>
            <div className="terms-info">
              <strong>User Content:</strong> You retain ownership of any content you create using our Services. However, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and display your content solely for the purpose of providing the Services.
              <br/><br/>
              <strong>Our Content:</strong> All content and materials provided by us are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without our permission.
            </div>

            <div className="terms-header">7. Privacy Policy</div>
            <div className="terms-info">
              Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, 
              and protect your personal information.
            </div>

            <div className="terms-header">8. Termination</div>
            <div className="terms-info">
              We may terminate or suspend your access to our Services at any time, without notice, for conduct 
              that we believe violates these Terms or is harmful to other users or our Services.
            </div>

            <div className="terms-header">9. Disclaimers and Limitation of Liability</div>
            <div className="terms-info">
              <strong>Disclaimer:</strong> Our Services are provided "as is" without warranties of any kind, either express or implied. We do not guarantee that our Services will be error-free or uninterrupted.
              <br/><br/>
              <strong>Limitation of Liability:</strong> In no event shall we be liable for any indirect, incidental, or consequential damages arising from your use of our Services.
            </div>

            <div className="terms-header">10. Changes to Terms</div>
            <div className="terms-info">
              We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. 
              Your continued use of the Services after the changes take effect constitutes your acceptance of the new Terms.
            </div>

            <div className="terms-header">11. Governing Law</div>
            <div className="terms-info">
              These Terms shall be governed by and construed in accordance with the laws of Toronto, Ontario.
            </div>

            <div className="terms-header">12. Contact Us</div>
            <div className="terms-info">If you have any questions about these Terms, please contact us at [Your Contact Information].</div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="terms-mobile-page">
          <Header/>

          <div id="main">
            <div id="terms-header">Terms of Service</div>

            <div id="terms-lastupdated">
              Last Updated: September 30, 2024<br/><br/>

              Welcome to [Your App Name]! These Terms of Service ("Terms") govern your use of our web and desktop applications ("Services"). 
              By accessing or using our Services, you agree to these Terms. If you do not agree, please do not use our Services.
            </div>

            <div className="terms-header">1. Acceptance of Terms</div>
            <div className="terms-info">
              By using our Services, you confirm that you are at least 13 years old and have 
              the legal capacity to enter into these Terms.
            </div>

            <div className="terms-header">2. User Accounts</div>
            <div className="terms-info">
              <strong>Account Creation:</strong> To use certain features, you may need to create an account. 
              You agree to provide accurate, complete, and updated information.
              <br/><br/>
              <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of 
              your account credentials and for all activities that occur under your account.
            </div>

            <div className="terms-header">3. Free Trial</div>
            <div className="terms-info">
              <strong>Free Trial Period:</strong> New users will receive a one-week free trial of our Services, beginning on the day you create your account.
              <br/><br/>
              <strong>Subscription Activation:</strong> At the end of the trial period, your subscription will automatically 
              convert to a paid subscription at a rate of $9.99 per month, unless you cancel before the trial 
              ends.
            </div>

            <div className="terms-header">4. Subscription and Payment</div>
            <div className="terms-info">
              <strong>Fees:</strong> The subscription fee is $9.99 per month, billed monthly after the free trial ends.
              <br/><br/>
              <strong>Billing:</strong> We will charge your chosen payment method. You are responsible for maintaining updated payment information.
            </div>

            <div className="terms-header">5. User Responsibilities</div>
            <div className="terms-info">
              You agree not to use the Services to:

              <ul>
                <li>Violate any applicable laws or regulations.</li>
                <li>Impersonate any person or entity.</li>
                <li>Transmit any harmful or malicious code.</li>
                <li>Engage in any conduct that restricts or inhibits anyone's use of the Services.</li>
              </ul>
            </div>

            <div className="terms-header">5.1 Disclaimer of Responsibility</div>
            <div className="terms-info">
              We are not responsible for any misplaced, incorrect, or incomplete information provided by users. 
              It is your responsibility to ensure that all information entered into the Services is accurate and 
              up-to-date. We disclaim any liability for errors or omissions in user-generated content.
            </div>

            <div className="terms-header">6. Content Ownership</div>
            <div className="terms-info">
              <strong>User Content:</strong> You retain ownership of any content you create using our Services. However, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and display your content solely for the purpose of providing the Services.
              <br/><br/>
              <strong>Our Content:</strong> All content and materials provided by us are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without our permission.
            </div>

            <div className="terms-header">7. Privacy Policy</div>
            <div className="terms-info">
              Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, 
              and protect your personal information.
            </div>

            <div className="terms-header">8. Termination</div>
            <div className="terms-info">
              We may terminate or suspend your access to our Services at any time, without notice, for conduct 
              that we believe violates these Terms or is harmful to other users or our Services.
            </div>

            <div className="terms-header">9. Disclaimers and Limitation of Liability</div>
            <div className="terms-info">
              <strong>Disclaimer:</strong> Our Services are provided "as is" without warranties of any kind, either express or implied. We do not guarantee that our Services will be error-free or uninterrupted.
              <br/><br/>
              <strong>Limitation of Liability:</strong> In no event shall we be liable for any indirect, incidental, or consequential damages arising from your use of our Services.
            </div>

            <div className="terms-header">10. Changes to Terms</div>
            <div className="terms-info">
              We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. 
              Your continued use of the Services after the changes take effect constitutes your acceptance of the new Terms.
            </div>

            <div className="terms-header">11. Governing Law</div>
            <div className="terms-info">
              These Terms shall be governed by and construed in accordance with the laws of Toronto, Ontario.
            </div>

            <div className="terms-header">12. Contact Us</div>
            <div className="terms-info">If you have any questions about these Terms, please contact us at [Your Contact Information].</div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </MobileView>
    </>
  )
}
