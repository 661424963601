import "./mainheader.scss";
import { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { FiBell } from "react-icons/fi";
import { GoBellFill } from "react-icons/go";
import { AiOutlineUserAdd } from "react-icons/ai";
import { GiThink } from "react-icons/gi";
import { IoLogOutOutline } from "react-icons/io5";
import { BsCardChecklist, BsPercent } from "react-icons/bs";

import { VscReport } from "react-icons/vsc";

export default function Mainheader({ 
  numUsages,
  name,
  addNewMember = () => {}, // default function,
  getTheUsage,
  numNotifications,
  getTheNotifications,
  getTheUsageRecords,
  theReport,
  requestFeature,
  logout,
  isNotificationOpen,
  showControls
}) {
  const token = localStorage.getItem("token")

  return (
    <div>
      <BrowserView>
        <div id="mainheader-comp-web" style={{ height: token ? 35 : 90 }}>
          <div id="header-row">
            <div id="icon-holder" onClick={() => window.location = "/"}>
              <div className="column">
                <div id="icon">
                  <img alt="icon" src="/logo-white.png"/>
                </div>
              </div>
              <div className="column"><div id="icon-header">DETACH | <strong style={{ fontWeight: 100 }}>ALL-ON-ONE <strong>SCREEN</strong> MANAGEMENT PLATFORM</strong></div></div>
            </div>
            {(token && showControls) ? 
              <>                
                <div id="navs">
                  <div className="column"><div id="username">{name}</div></div>
                  <div className="nav" onClick={() => getTheNotifications()}>
                    {isNotificationOpen ? 
                      <GoBellFill style={{ color: 'black', height: '100%', width: '100%' }}/>
                      :
                      <>
                        {numNotifications > 0 && <strong style={{ fontSize: 12, marginBottom: -17, marginLeft: 14, marginTop: -5, position: 'absolute' }}>{numNotifications}</strong>}
                        <FiBell style={{ color: numNotifications === 0 ? 'black' : 'red', height: '100%', width: '100%' }}/>
                      </>
                    }
                  </div>
                  {token && <div className="nav" onClick={() => addNewMember()}><AiOutlineUserAdd style={{ height: '100%', width: '100%' }}/></div>}
                  <div className="nav" onClick={() => getTheUsageRecords()}>
                    <div className="column"><div className="nav-header">Usage</div></div>
                    <div className="column"><div className="nav-icon"><BsPercent style={{ height: '100%', width: '100%' }}/></div></div>
                  </div>
                  <div className="nav" onClick={() => window.location = "/pricing"}>
                    <div className="column"><div className="nav-header">Subscription</div></div>
                  </div>
                  <div className="nav" onClick={() => theReport()}>
                    <div className="column"><div className="nav-header">Report</div></div>
                    <div className="column"><div className="nav-icon"><VscReport style={{ height: '100%', width: '100%' }}/></div></div>
                  </div>
                  <div className="nav" onClick={() => requestFeature()}>
                    <div className="column"><div className="nav-header">Request a Feature</div></div>
                    <div className="column"><div className="nav-icon"><GiThink style={{ height: '100%', width: '100%' }}/></div></div>
                  </div>
                  <div className="nav" onClick={() => logout()}>
                    <div className="column"><div className="nav-header">Log-Out</div></div>
                    <div className="column"><div className="nav-icon"><IoLogOutOutline style={{ height: '100%', width: '100%' }}/></div></div>
                  </div>
                </div>
              </>
              :
              <div id="auths">
                <div className="auth" onClick={() => window.location = "/about"}>ABOUT</div>
                {/* <div className="auth" onClick={() => window.location = "/login"}>LOGIN</div>
                <div className="auth" onClick={() => window.location = "/register"}>REGISTER</div> */}
              </div>
            }
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="mainheader-comp-mobile" style={{ height: showControls ? 70 : 'auto' }}>
          <div id="header-navs">
            {(token && showControls) && (
              <div className="header-navs-row">
                <div className="header-nav" onClick={() => getTheNotifications()}>
                  {isNotificationOpen ? 
                    <GoBellFill style={{ color: 'black', height: '100%', width: '100%' }}/>
                    :
                    <>
                      {numNotifications > 0 && <strong style={{ fontSize: 12, marginBottom: -17, marginLeft: 14, marginTop: -5, position: 'absolute' }}>{numNotifications}</strong>}
                      <FiBell style={{ color: numNotifications === 0 ? 'black' : 'red', height: '100%', width: '100%' }}/>
                    </>
                  }
                </div>
                {token && <div className="header-nav" onClick={() => addNewMember()}><AiOutlineUserAdd style={{ color: 'black', height: '100%', width: '100%' }}/></div>}
                <div className="header-nav" onClick={() => getTheUsageRecords()}><BsPercent style={{ color: 'black', height: '100%', width: '100%' }}/></div>
              </div>
            )}

            <div id="icon-row" onClick={() => window.location = "/"}>
              <div className="column">
                <div id="icon" onClick={() => window.location = "/"}>
                  <img alt="icon" src="/logo.png"/>
                </div>
              </div>
            </div>

            {(token && showControls) && (
              <div className="header-navs-row">
                <div className="header-nav" onClick={() => theReport()}><VscReport style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                <div className="header-nav" onClick={() => requestFeature()}><GiThink style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                <div className="header-nav" onClick={() => logout()}><IoLogOutOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div>
              </div>
            )}
          </div>

          {(token && showControls) ? 
            <div className="row" style={{ marginBottom: 2 }}>
              <div id="zoom-actions">
                <div id="name-header">{name}</div>
              </div>
            </div>
            :
            <>
              <div className="icon-mini-header">ALL-ON-ONE SCREEN MANAGEMENT TOOL</div>

              <div id="auths">
                <div className="auth" onClick={() => window.location = "/about"}>ABOUT</div>
                <div className="auth" onClick={() => window.location = "/login"}>LOGIN</div>
                <div className="auth" onClick={() => window.location = "/register"}>REGISTER</div>
              </div>
            </>
          }
        </div>
      </MobileView>
    </div>
  )
}
