import "./usagerecords.scss";
import { BrowserView, MobileView } from "react-device-detect";

export default function Usagerecords({ usageRecords }) {
  return (
    <div id="usage-records">
      <BrowserView>
        <div id="usage-records-web">
          <div id="plan-header">Current Plan: {usageRecords.plan}</div>
          
          {!localStorage.getItem("adderBy") && <div id="change-plan" onClick={() => window.location = "/upgradeplan"}>Change plan</div>}

          {usageRecords.showUsage ? 
            <>
              <div id="usage-records-header">YOUR USAGE</div>

              <div className="row">
                <div id="records">
                  <div className="record">
                    <div className="column"><div className="record-header">Company(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.company.use < usageRecords.company.limit ? 
                        usageRecords.company.use
                        :
                        <strong>{usageRecords.company.use}</strong>
                      } of <strong>{usageRecords.company.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Project(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.project.use < usageRecords.project.limit ? 
                        usageRecords.project.use
                        :
                        <strong>{usageRecords.project.use}</strong>
                      } of <strong>{usageRecords.project.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Team(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.team.use < usageRecords.team.limit ? 
                        usageRecords.team.use
                        :
                        <strong>{usageRecords.team.use}</strong>
                      } of <strong>{usageRecords.team.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Member(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.member.use < usageRecords.member.limit ? 
                        usageRecords.member.use
                        :
                        <strong>{usageRecords.member.use}</strong>
                      } of <strong>{usageRecords.member.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Todolist(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.todolist.use < usageRecords.todolist.limit ? 
                        usageRecords.todolist.use
                        :
                        <strong>{usageRecords.todolist.use}</strong>
                      } of <strong>{usageRecords.todolist.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Task(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.task.use < usageRecords.task.limit ? 
                        usageRecords.task.use
                        :
                        <strong>{usageRecords.task.use}</strong>
                      } of <strong>{usageRecords.task.limit}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div id="usage-records-header" style={{ paddingBottom: 20 }}>You have unlimited USAGE</div>
          }
        </div>
      </BrowserView>
      <MobileView>
        <div id="usage-records-mobile">
          <div id="plan-header">Current Plan: {usageRecords.plan}</div>
          
          {!localStorage.getItem("adderBy") && <div id="change-plan" onClick={() => window.location = "/upgradeplan"}>Change plan</div>}

          {usageRecords.showUsage ? 
            <>
              <div id="usage-records-header">YOUR USAGE</div>

              <div className="row">
                <div id="records">
                  <div className="record">
                    <div className="column"><div className="record-header">Company(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.company.use < usageRecords.company.limit ? 
                        usageRecords.company.use
                        :
                        <strong>{usageRecords.company.use}</strong>
                      } of <strong>{usageRecords.company.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Project(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.project.use < usageRecords.project.limit ? 
                        usageRecords.project.use
                        :
                        <strong>{usageRecords.project.use}</strong>
                      } of <strong>{usageRecords.project.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Team(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.team.use < usageRecords.team.limit ? 
                        usageRecords.team.use
                        :
                        <strong>{usageRecords.team.use}</strong>
                      } of <strong>{usageRecords.team.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Member(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.member.use < usageRecords.member.limit ? 
                        usageRecords.member.use
                        :
                        <strong>{usageRecords.member.use}</strong>
                      } of <strong>{usageRecords.member.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Todolist(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.todolist.use < usageRecords.todolist.limit ? 
                        usageRecords.todolist.use
                        :
                        <strong>{usageRecords.todolist.use}</strong>
                      } of <strong>{usageRecords.todolist.limit}</strong>
                    </div>
                  </div>
                  <div className="record">
                    <div className="column"><div className="record-header">Task(s): </div></div>
                    <div className="record-usage">
                      {usageRecords.task.use < usageRecords.task.limit ? 
                        usageRecords.task.use
                        :
                        <strong>{usageRecords.task.use}</strong>
                      } of <strong>{usageRecords.task.limit}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div id="usage-records-header" style={{ paddingBottom: 20 }}>You have unlimited USAGE</div>
          }
        </div>
      </MobileView>
    </div>
  )
}
