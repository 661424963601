import "./notifications.scss";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Notifications({ notification, viewTaskInfo, seeNotes }) {
  const { list, loading } = notification
  
  return (
    <div id="notification-comp">
      <BrowserView>
        <div id="notification-web">
          <div id="notification">
            <div id="notification-header">{list.length} new alert(s)</div>

            {!loading ? 
              list.length > 0 ? 
                <div id="notification-list">
                  {list.map((item, index) => (
                    <div className={"notification" + (item.created ? "-hover" : "")} key={"notification-" + index} onClick={() => {
                      if (item.type === "commented") {
                        viewTaskInfo(item.id)
                      } else if (item.type === "note") {
                        seeNotes(item.id)
                      }
                    }}>
                      <div className="notification-header">
                        {item.header}
                      </div>

                      {(item.completed || item.type === "commented") && (
                        <div className="notification-header" style={{ margin: '0 10px' }}>{
                          item.taskName
                          ||
                          item.todolistName
                        }</div>
                      )}
                      
                      <div className="notification-time">{
                        item.created
                        ||
                        item.endStamp
                        ||
                        item.completed
                      }</div>
                    </div>
                  ))}
                </div>
                :
                <div id="no-notification-header">No Notification(s) YET</div>
              :
              <div id="loading-container" style={{ height: '50%', margin: '5% 0' }}>
                <div id="loading-icon" style={{ height: 30, width: 30 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            }
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="notification-mobile">
          <div id="notification">
            <div id="notification-header">{list.length} new alert(s)</div>

            {!loading ? 
              list.length > 0 ? 
                <div id="notification-list">
                  {list.map((item, index) => (
                    <div className={"notification" + (item.created ? "-hover" : "")} key={"notification-" + index} onClick={() => {
                      if (item.type === "commented") {
                        viewTaskInfo(item.id)
                      } else if (item.type === "note") {
                        seeNotes(item.id)
                      }
                    }}>
                      <div className="notification-header">
                        {item.header}
                      </div>

                      {(item.completed || item.type === "commented") && (
                        <div className="notification-header" style={{ margin: '0 10px' }}>{
                          item.taskName
                          ||
                          item.todolistName
                        }</div>
                      )}
                      
                      <div className="notification-time">{
                        item.created
                        ||
                        item.endStamp
                        ||
                        item.completed
                      }</div>
                    </div>
                  ))}
                </div>
                :
                <div id="no-notification-header">No Notification(s) YET</div>
              :
              <div id="loading-container" style={{ height: '50%', margin: '5% 0' }}>
                <div id="loading-icon" style={{ height: 30, width: 30 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            }
          </div>
        </div>
      </MobileView>
    </div>
  )
}
