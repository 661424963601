import "./report.scss";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { reportProblem } from "../../apis/user"

export default function Report({ close }) {
  const [input, setInput] = useState('')
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrormsg] = useState('')

  const reportTheProblem = () => {
    if (input) {
      const data = {
        token: localStorage.getItem("token"),
        input
      }

      setLoading(true)

      reportProblem(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setLoading(false)
            setSent(true)

            setTimeout(function () {
              close()
            }, 2000)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    } else {
      setErrormsg('Nothing is entered')
    }
  }

  return (
    <div>
      <BrowserView>
        <div id="report-web">
          <div id="report-box">
            <form id="report-container" style={{ opacity: loading ? 0.9 : 1 }} onSubmit={e => {
              reportTheProblem()

              e.preventDefault()
            }}>
              <div id="report-header">Report a problem</div>

              <div id="report-input">
                <textarea type="text" maxLength="200" disabled={loading || sent} onChange={e => {
                  setInput(e.target.value)
                  setErrormsg('')
                }}>{input}</textarea>
              </div>

              <div id="errormsg">{errorMsg}</div>

              {loading && (
                <div id="loading-box">
                  <div id="loading-container" style={{ marginVertical: 10 }}>
                    <div id="loading-icon" style={{ height: 30, width: 30 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              )}

              {sent && <div id="sent-header">Thank you for your contribution. Your message has been sent</div>}

              {!sent && (
                <div id="report-actions">
                  <div className={"report-action" + (loading ? "-disabled" : "")} onClick={loading ? null : () => close()}>Cancel</div>
                  <button className={"report-action" + (loading ? "-disabled" : "")} disabled={loading}>Report</button>
                </div>
              )}
            </form>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="report-mobile">
          <div id="report-box">
            <form id="report-container" style={{ opacity: loading ? 0.9 : 1 }} onSubmit={e => {
              reportTheProblem()

              e.preventDefault()
            }}>
              <div id="report-header">Report a problem</div>

              <div id="report-input">
                <textarea type="text" maxLength="200" disabled={loading || sent} onChange={e => {
                  setInput(e.target.value)
                  setErrormsg('')
                }}>{input}</textarea>
              </div>

              <div id="errormsg">{errorMsg}</div>

              {loading && (
                <div id="loading-box">
                  <div id="loading-container" style={{ marginVertical: 10 }}>
                    <div id="loading-icon" style={{ height: 30, width: 30 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              )}

              {sent && <div id="sent-header">Thank you for your contribution. Your message has been sent</div>}

              {!sent && (
                <div id="report-actions">
                  <div className={"report-action" + (loading ? "-disabled" : "")} onClick={loading ? null : () => close()}>Cancel</div>
                  <button className={"report-action" + (loading ? "-disabled" : "")} disabled={loading}>Report</button>
                </div>
              )}
            </form>
          </div>
        </div>
      </MobileView>
    </div>
  )
}
