import "./priceplans.scss";
import { BrowserView, MobileView } from "react-device-detect";

export default function Priceplans() {
  return (
    <div id="price-plan-comp">
      <BrowserView>
        <div id="price-plan-comp-web">
          <div id="price-plan">
            <div id="price-plans">
              <div className="plan">
                <div className="plan-top">
                  <div className="plan-header">STANDARD</div>
                  <hr/>
                  <div className="plan-price-header">$9.99 CAD / Month</div>
                  <ul>
                    <li>Up to 25 Tasks, 25 Schedules and 20 Todolists</li>
                    <li>Up to 50 Notes</li>
                    <li>Up to 5 Projects</li>
                    <li>1 Company, Team</li>
                    <li>Up to 10 Employees</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="price-plan-comp-mobile">
          <div id="price-plan">
            <div id="price-plans">
              <div className="plan">
                <div className="plan-top">
                  <div className="plan-header">STANDARD</div>
                  <hr/>
                  <div className="plan-price-header">$9.99 CAD / Month</div>
                  <ul>
                    <li>Up to 25 Tasks, 25 Schedules and 20 Todolists</li>
                    <li>Up to 50 Notes</li>
                    <li>Up to 5 Projects</li>
                    <li>1 Company, Team</li>
                    <li>Up to 10 Employees</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  )
}
